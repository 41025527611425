import { useStreetSuggestions } from '../../../hooks/useStreetSuggestions.hook';
import { Checkbox, TextField } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { IPhaseAddress } from '../../../types';
import { Autocomplete } from '@material-ui/lab';
import { useGeneralStyles } from '../../../style/generalStyles';
import classNames from 'classnames';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { usePhaseDetailStyles } from '../../../style/phaseDetails.styles';
import { translate } from '../../../common/translations/translate';
import { useToggle } from 'react-use';
import { AddCircleOutline as AddIcon } from '@material-ui/icons';
import { usePhaseLocationForm } from '../../../hooks/forms/usePhaseLocationForm.hook';

interface IProps {
  phaseId?: number;
  onAddAddress: (address: IPhaseAddress) => void;
}

export const PhaseAddLocationForm: FC<IProps> = ({ phaseId, onAddAddress }) => {
  const { onSubmit, formMethods } = usePhaseLocationForm(onAddAddress);
  const {
    register,
    watch,
    setValue,
    resetField,
    formState: { errors },
  } = formMethods;
  const [input, setInput] = useState('');
  const suggestions = useStreetSuggestions(input);
  const C = usePhaseDetailStyles();
  const G = useGeneralStyles();
  const [streetNumberUnknown, toggleStreetNumberUnknown] = useToggle(false);

  const onChange = (e: React.ChangeEvent<{}>, value: string | null) => {
    value && setValue('streetName', value);
  };

  const onClickStreetNumberUnknown = () => {
    if (!streetNumberUnknown) {
      resetField('streetNumberFrom');
      resetField('streetNumberTo');
    }
    toggleStreetNumberUnknown();
  };

  return (
    <form className={C.addLocationsForm} id={`newPhaseLocation-${phaseId}`} noValidate>
      <div className={classNames(G.flexRowSpaceBetween, G.fullWidth)}>
        <Autocomplete
          className={C.streetName}
          {...register('streetName', { required: true })}
          value={watch('streetName')}
          placeholder={'Straatnaam'}
          options={suggestions.map(({ label }) => label)}
          onChange={onChange}
          onInputChange={(e: any) => setInput(e.target.value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={translate('sgw.requests.detail.phases.editLocationsTable.streetName')}
              required
              error={!!errors.streetName}
            />
          )}
        />
        <TextField
          className={C.streetNumberFormColumn}
          disabled={streetNumberUnknown}
          {...register('streetNumberFrom', { required: !streetNumberUnknown })}
          required={!streetNumberUnknown}
          label={translate('sgw.requests.detail.phases.editLocationsTable.streetNumberFrom')}
          error={!!errors.streetNumberFrom}
        />
        <TextField
          className={C.streetNumberFormColumn}
          disabled={streetNumberUnknown}
          {...register('streetNumberTo', { required: !streetNumberUnknown })}
          required={!streetNumberUnknown}
          label={translate('sgw.requests.detail.phases.editLocationsTable.streetNumberTo')}
          error={!!errors.streetNumberTo}
        />
      </div>
      <div className={classNames(G.flexRowEnd, C.checkbox)}>
        <Checkbox checked={streetNumberUnknown} onClick={onClickStreetNumberUnknown} />
        <div>{translate('sgw.requests.detail.phases.editLocationsTable.streetNumberUnknown')}</div>
      </div>
      <div className={classNames(G.flexRowEnd)}>
        <Button.Green
          type="button"
          onClick={onSubmit}
          form={`newPhaseLocation-${phaseId}`}
          startIcon={<AddIcon fontSize={'small'} />}
        >
          {translate('sgw.requests.detail.phases.editLocationsTable.add')}
        </Button.Green>
      </div>
    </form>
  );
};
