import { Feature, Point } from 'geojson';
import { IAddress, ISorting } from '../common';
import { IUser, IUserData } from '../common/types/user';
import { IById, INormalizedStateById } from '../common/utils/normalized.util';
import { IIntakeType, IPublicDomainIntake, IRequestLocationForm } from './intake.types';
import { ICountry } from './metadata.types';
import { IPayment, PaymentState } from './payment.types';
import { IRequestMessageAttachments, IRequestMessagesList } from './requestMessages.types';
import { IWithAttachments } from './storage.types';
import { IWorkOrderItem } from './workOrder.types';
import { ISuggestion } from './geolocation.types';
import { CarFreeZonesFilterState, IGate } from './carFreeZones.types';
import { IPincode } from './services.types';
import { IPagedTableState } from './generic.types';
import { IGeometry } from './geojson.types';
import { RequestParkingBanState } from './parkingBan.types';
import { IPermittedPlate } from './permittedPlate.types';
import { ICost } from './cost.types';
import { IRequestOverview } from './requestOverview.types';
import { RequestStates } from './requestStates.types';
import { IRequestReason, IRequestReference, IRequestUser, MessageState, RequestReason } from './requestInfo.types';

export interface IRequestsFilter {
  fromDate?: string;
  untilDate?: string;
  mine?: boolean;
  toBePickedUp?: boolean;
  urgent?: boolean;
  extensionStates?: RequestExtensionState[];
  userRequested?: number;
  userResponsible?: number;
  messageStates?: MessageState[];
  search?: {
    bypassOtherFilters: boolean;
    field: RequestSearchField;
    value?: string;
  };
  requestStates?: string[];
  requestTypes?: string[];
  requestReasons?: RequestReason[];
  streetName?: string;
  priorities?: RequestPriority[];
  paymentStates?: PaymentState[];
  parkingBanStates?: RequestParkingBanState[];
  requestedFrom?: string;
  requestedTo?: string;
  carFreeZone?: string[];
  carFreeZoneState?: CarFreeZonesFilterState[];
}

export interface IRequestsHistoryApi {
  all: boolean;
  id: string;
  sorting: ISorting;
}

export interface IRequestsHistory {
  data: string;
  timestamp: string;
  type: string;
  userName?: string;
}

export interface IRequestHistoryState {
  history: IRequestsHistory[];
  total: number;
}

export interface IRequestsPermitHistory {
  filePath: string;
  filename: string;
  timeChanged: string;
}

export interface IRequestsApi {
  car_free_zone?: string[];
  car_free_zone_state?: string[];
  created_from_date?: string;
  created_until_date?: string;
  emergency_procedure?: boolean;
  event_name?: string;
  extension_states?: RequestExtensionState[];
  external_reference?: string;
  from_date?: string;
  message_states?: string[];
  order?: 'desc' | 'asc';
  page?: number;
  page_size?: number;
  payment_states?: PaymentState[];
  priorities?: number[];
  publicdomainintake_types?: string[];
  publicdomainintake_states?: RequestParkingBanState[];
  reasons?: RequestReason[];
  reference_id?: string;
  sort?: string;
  states?: string[];
  street_name?: string;
  until_date?: string;
  user_responsible?: number | '';
  user_requested?: number;
  user_requested_name?: string;
  utility_company_id?: number;
}

export interface IRequestLocation {
  id: string;
  locationId: string;
}

export interface IRequestLocationPatch extends IRequestLocation, Partial<IWithAttachments> {
  anprRegularisation?: boolean;
  numberOfSigns?: number;
  numberOfZoneSigns?: number;
  streetNumberFrom?: string;
  streetNumberTo?: string;
  parkedPlates?: string[];
  permittedPlates?: IPermittedPlate[];
  noParkedVehicles?: boolean;
  signsPlacedAt?: string;
  carFreeZone?: number;
  carFreeZoneGateEntrance?: number | null;
  carFreeZoneGateExit?: number | null;
}

export interface IRequestPatchAttachment {
  id: string;
  attachments: {
    file: string;
    name: string;
  }[];
}

export interface IRequestLocationDetail extends IWithAttachments {
  id: string;
  permitRequestId: string;
  type: IIntakeType;
  workOrderItems: IWorkOrderItem[];
  carFreeZoneGateEntrance?: IGate;
  carFreeZoneGateExit?: IGate;
}

interface IRequestPatch {
  id: string;
}

export interface IRequestPatchPriority {
  id: number;
  priority: number;
}

export interface IRequestPatchCluster {
  id: number;
  cluster: string;
}

export interface IRequestPatchUserResponsible extends IRequestPatch {
  userResponsible: string | null;
  state?: RequestStates;
}

export interface IRequestPatchApprove extends IRequestPatch {
  reasonForApproval: string | null;
}

export interface IRequestPatchReject extends IRequestPatch {
  reasonForRejection: string | null;
}

export interface IRequestPatchInternalNotes extends IRequestPatch {
  backofficeNotes: string;
  shouldAppend?: boolean;
}

export interface IRequestPatchPublicDomainIntakes extends IRequestPatch {
  publicDomainIntakes: IRequestLocationForm[];
}

interface IWithExtensionId {
  extensionId: string;
}

export interface IRequestAcl {
  can_assign_request_to_oneself?: boolean;
  can_unassign_request_from_oneself?: boolean;
  can_edit_attachments?: boolean;
  can_edit_locations?: boolean;
  messages_can_edit?: boolean;
  messages_can_resolve?: boolean;
  messages_can_view?: boolean;
  can_approve?: boolean;
  can_reject?: boolean;
  can_reset_pincode?: boolean;
  view_pincode?: boolean;
  can_edit_signs?: boolean;
}

export interface IRequest extends IRequestReference, IWithAttachments, Partial<IUserData> {
  acl: IRequestAcl;
  backofficeNotes?: string;
  birthday?: string;
  billingMailedAt?: string;
  bus?: string;
  city: string;
  cluster: ICluster | [];
  comment: string;
  companyId?: string;
  companyName?: string;
  cost?: ICost;
  country: ICountry;
  createdBy?: IRequestUser;
  emailAddress?: string;
  entireDay: boolean;
  eventName?: string;
  externalReference?: string;
  firstName: string;
  ibanNumber?: string;
  lastName: string;
  manualPlacementUrl?: string;
  needsPayment: boolean;
  needsPlacement: boolean;
  numberOfPlacedSigns: number;
  numberOfRequestedSigns: number;
  onlyOnWeekdays: boolean;
  originalDateUntil: string;
  secondToLastDateUntil?: string;
  payments?: IPayment[];
  permit?: IPermit;
  pincode?: IPincode;
  publicDomainIntakes: IPublicDomainIntake[];
  phoneNumber: string;
  preferredMoment?: string;
  reason: IRequestReason;
  reasonForApproval?: string;
  reasonForEdit?: string;
  reasonForRejection?: string;
  requestAddress?: IAddress;
  showEditableFieldWarning?: boolean;
  ssn?: string;
  street: string;
  streetNumber: string;
  timeFrom: string;
  timeUntil: string;
  userRequested: IUser;
  utilityCompanyRequested?: {
    id: number;
    name: string;
  };
  withUtilityCompanyLimitations?: boolean;
  zipCode: string;
  sgwRequestId?: number;

  invoice?: {
     requested?: boolean;
     requestedForCompany?: boolean;
     companyId?: string;
     companyName?: string;
     addressStreet?: string;
     addressStreetNumber?: string;
     addressBus?: string;
     addressZipCode?: string;
     addressCity?: string;
     addressCountry?: string;
  }
}

export interface IPermit {
  downloadUrl?: string;
  pending?: boolean;
  longPending?: boolean;
  error?: boolean;
}

export interface IRequestData extends IRequestOverview {
  fullAddresses: string[];
  types: string[];
}

export interface IRequestMetadataState {
  state: string;
  name: string;
  displayName?: string;
  displayRemarks?: string;
  color: string;
}

export interface ICluster {
  cluster: string;
  name: string;
  displayName?: string;
}

export interface IRequestMetadata {
  reasons: {
    reason: RequestReason;
    name: string;
    displayName?: string;
    displayRemarks?: string;
    id: number;
    needsPlacement: boolean;
    needsPayment: boolean;
    type: string;
    acl: {
      showOnFrontOffice?: boolean;
    };
  }[];
  types: {
    type: string;
    name: string;
    displayName?: string;
    displayRemarks?: string;
  }[];
  signTypes: {
    type: string;
    name: string;
    displayName?: string;
    displayRemarks?: string;
  }[];
  states: IRequestMetadataState[];
  tenant: {
    earliestStartDateAllowedForRequests: string;
    earliestStartDateAllowedForEmergencyRequests: string;
    allowedZipCodes: {
      zipCode: number;
      name: string;
    }[];
    bankTransferAccount: string;
    bankTransferBeneficiary: string;
    id: number;
  };
  messageStates: {
    messageState: string;
    name: string;
    displayName?: string;
    displayRemarks?: string;
  }[];
  countries: {
    country: string;
    name: string;
  }[];
  prices: {
    requestFixedAdministrativeCost: number;
    requestFixedEmergencyCost: number;
    extensionFixedAdministrativeCost: number;
    extensionFixedEmergencyCost: number;
    vatPercent: number;
  };
  clusters: ICluster[];
  sgwStates: IRequestMetadataState[];
}

export interface IRequestExtensionPatch extends IWithExtensionId {
  id: number;
  reasonForApproval?: string | null;
  reasonForRejection?: string | null;
  extensionId: string;
  state: RequestStates;
}

export enum RequestPriority {
  PRIORITY_NORMAL = 1,
  PRIORITY_HIGH = 2,
}

export enum UserResponsibleFilterStatus {
  ALL_USERS = 'ALL_USERS',
  UNASSIGNED = 'UNASSIGNED',
  ASSIGNED_TO_ME = 'ASSIGNED_TO_ME',
  SELECTED_USER = 'SELECTED_USER',
}

export enum RequestSearchField {
  user_requested_name = 'user_requested_name',
  street_name = 'street_name',
  reference_id = 'reference_id',
  event_name = 'event_name',
  external_reference = 'external_reference',
}

export enum RequestExtensionState {
  draft = 'draft',
  submitted = 'submitted',
  approved = 'approved',
  rejected = 'rejected',
  unpaid = 'unpaid',
}

export const OPEN_EXTENSION_STATES = [RequestExtensionState.submitted];
export const OPEN_EXTENSION_STATES_WITHOUT_ORDER_API = [RequestExtensionState.submitted, RequestExtensionState.draft];

interface IFeatureProperties {
  key: number;
  current?: boolean;
  description: string;
  id: number;
  title: string;
  type: string;
}

export type IFeature = Feature<IGeometry, IFeatureProperties>;

export interface IRequestsState {
  alreadyAssigned: boolean;
  conflicts: INormalizedStateById<IFeature[]>;
  copyRequest: IRequest | null;
  currentConflictKey: number | null;
  detail: INormalizedStateById<IRequest> & { editableFields: string[] };
  draftIds: string[];
  exporting: boolean;
  geocodesById: IById<Point>;
  history: {
    requestHistory: IRequestHistoryState;
    permitHistory: IRequestsPermitHistory[];
  };
  list: IPagedTableState<IRequestOverview, IRequestsFilter>;
  loading: boolean;

  saving: boolean;
  locationDetail: IRequestLocationDetail | null;
  metadata: IRequestMetadata | null;
  request: IRequest | null;
  requestExtensions: IRequestExtension[] | null;
  reverseGeocodesById: IById<IRequestLocationForm>;
  requestMessageAttachments: IRequestMessageAttachments;
  requestMessagesList: IRequestMessagesList;
  requestReasons: IRequestReason[];
  requestReasonFilters: IRequestReasonFilter[];
  suggestions: ISuggestion[];
  isGeoCodeCorrect?: boolean;
}

export interface IRequestExtensionAcl {
  can_be_approved: boolean;
  can_be_rejected: boolean;
  show_approve_warning: boolean;
}

export interface IRequestExtension {
  id: string;
  acl: IRequestExtensionAcl;
  cost: ICost;
  state: {
    state: RequestExtensionState;
    name: string;
    transitions: string[];
  };
  dateUntil: string;
  totalAmountPayed: number;
  permitRequest?: IRequest;
  payments?: IPayment[];
  reasonForApproval?: string;
  reasonForRejection?: string;
}

export interface IRequestReasonFilter {
  id: string;
  name: string;
}

export interface IRequestDetailComponent {
  requestId: string;
  visible?: boolean;
}

export interface IRequestApprovalComponent extends IRequestDetailComponent {
  onApproveRequest: (reasonForApproval: string | null) => void;
  onRejectRequest: (reasonForRejection: string | null) => void;
  onReviewRequest(): void;
}

export interface IRequestCancelButtonComponent extends IRequestDetailComponent {
  onCancelRequest(): void;
}

export interface IRequestResetPincodeButtonComponent extends IRequestDetailComponent {
  onResetPincode(): void;
}

export interface IRequestCopyButtonComponent extends IRequestDetailComponent {
  onCopyRequest: () => void;
}

export interface IRequestDetailHeaderComponent
  extends IRequestApprovalComponent,
    IRequestCancelButtonComponent,
    IRequestCopyButtonComponent {}

export const EDITABLE_STATES = [
  RequestStates.approved,
  RequestStates.draft,
  RequestStates.reviewing,
  RequestStates.submitted,
  RequestStates.waiting_for_payment,
];

export enum RequestReasonType {
  events = 'events',
  citizens = 'citizens',
  sweeping = 'sweeping',
  filming = 'filming',
}

export interface ICheckIban {
  valid: boolean;
  msgs: string[];
}
