import { IPagedTableState, IWithId, IWithName, IWithTimestamps } from './generic.types';
import { IById } from '../common/utils/normalized.util';
import { IService, IServiceFilter } from './services.types';
import { IRequestOverview } from './requestOverview.types';
import { RequestStates } from './requestStates.types';
import { IGeometry } from './geojson.types';

export interface ICarFreeZone extends IWithId, IWithTimestamps {
  active: boolean;
  city: string;
  description: string;
  externalReference: string;
  gates: IGate[];
  gatesSyncedAt?: string;
  gisId: string;
  latitude: number;
  longitude: number;
  name: string;
  numberOfActiveRequests: number;
  numberOfGates: number;
  polygon?: IGeometry;
  street: string;
  streetNumber: string;
  type: CarFreeZoneType;
  zipCode: number;
}

export enum CarFreeZoneType {
  anpr = 'anpr',
  krautli = 'krautli',
  limited = 'limited',
  vialis = 'vialis',
}

export interface ICarFreeZoneState {
  list: IPagedTableState<ICarFreeZone, ICarFreeZonesFilter>;
  carFreeZones: ICarFreeZone[];
  templates: IById<IMoovTemplate[]>;
  requests: IRequestState;
  gates: IGatesState;
  exemptions: IExemptionsState;
}

export interface IGatesState {
  isSyncing: boolean;
  list: IPagedTableState<IGate, IGatesFilter>;
}

export interface IExemptionsState {
  isSyncing: boolean;
  list: IPagedTableState<IExemption, IExemptionsFilter>;
}

export interface IRequestState {
  list: IPagedTableState<IRequestOverview>;
}

export interface ILinkCarFreeZoneWithTemplateApi {
  id: string;
  templateId: string;
}

export interface ICarFreeZoneRequestsFilter {
  car_free_zone?: string[]; // in snake case to comply with backend
  states?: RequestStates[];
}

export enum CarFreeZonesFilterState {
  active = 'active',
  inactive = 'inactive',
}

export enum CarFreeZonesFilterType {
  anpr = 'anpr',
  krautli = 'krautli',
  poles = 'Paaltjes',
  trafficSignC3 = 'Verkeersbord C3',
  pedestrianZoneSign = 'Voetgangerszone Bord',
}

export type CarFreeZoneFilterKey = keyof typeof CarFreeZonesFilterType;

export interface ICarFreeZonesFilter {
  query?: string;
  state?: CarFreeZonesFilterState[];
  type?: CarFreeZonesFilterType[];
  gis_sync_layer?: number | number[];
}

export interface IMoovTemplate extends IWithTimestamps {
  id: number;
  name: string;
}

export interface IMoovState {
  syncTemplatesLoading: boolean;
  allListIds: string[];
  list: IPagedTableState<IService, IServiceFilter>;
}

export interface IMoovImportResponse {
  imported: number;
  created: IMoovTemplate[];
  deleted: Partial<IMoovTemplate>[];
  updated: Partial<IMoovTemplate>[];
}

export enum GateSas {
  in = 'IN',
  out = 'UIT',
  inOut = 'IN/UIT',
  none = 'N/A',
  automaticOut = 'Automatisch uit',
  inAutomaticOut = 'In/Automatisch uit',
}

export interface IGate extends IWithId, IWithName {
  active: boolean;
  address: string;
  type: string;
  sas: GateSas;
  gisId?: string;
}

export interface IGatesFilter {
  carFreeZoneId?: string;
}

export interface IExemption {
  id: string;
  exemptionId: string;
  active?: boolean;
  type: string;
  contactPerson: string;
  licensePlate: string;
  pincode: string;
  dateFrom: string;
  dateUntil: string;
  zapper: string;
  zone: string;
  zoneType: string;
}

export enum ExemptionsFilterOnType {
  licensePlate = 'licensePlate',
  pincode = 'pincode',
  zapper = 'zapper',
}

export enum ExemptionsType {
  request = 'request',
  service = 'service',
}

export enum ExemptionFilterState {
  active = 'active',
  inactive = 'inactive',
}

export interface IExemptionsFilter {
  licensePlate?: string;
  pincode?: string;
  zapper?: string;
  type?: ExemptionsType[];
  zoneType?: CarFreeZonesFilterType[];
  dateFrom?: string;
  dateUntil?: string;
  active?: boolean;
}

// Component interfaces:
export interface ILinkTemplateComponentProps {
  linkedTemplates?: IMoovTemplate[];
  unlinkedTemplates?: { value: string; label: string }[];
  onLinkTemplate: (templateId?: string) => void;
  onUnlinkTemplate: (templateId: string) => void;
}
