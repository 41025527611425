import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import classNames from 'classnames';
import { translate } from '../../../common/translations/translate';
import { durationInDays, formatDateTimeToDisplayDate } from '../../../common/utils/date.util';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { IRetributionCorrectionForm } from '../../../types';
import { useSelector } from 'react-redux';
import { getPhasesWithTotalAndMaxSurfaceArea } from '../../../store/selectors/sgwRequest.selectors';
import { createUseStyles } from 'react-jss';
import { useGeneralStyles } from '../../../style/generalStyles';
import { useSpacingStyles } from '../../../style/spacing.styles';
import { COLORS } from '../../../common';
import { Visible } from '../../../common/components/layout/Visible.component';

const useStyles = createUseStyles({
  table: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  tableContainer: {
    borderBottom: `1px solid ${COLORS.GREY_SPACER}`,
  },
  subtitle: {
    fontWeight: 'normal',
  },
  textField: {
    width: '75px',
  },
  inputCell: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '4px',
  },
});

export const RetributionCorrectionFormPhaseTable = () => {
  const C = useStyles();
  const G = { ...useGeneralStyles(), ...useSpacingStyles() };
  const phases = useSelector(getPhasesWithTotalAndMaxSurfaceArea());
  const { register, control, watch } = useFormContext<IRetributionCorrectionForm>();
  const { fields } = useFieldArray({
    control,
    name: 'phases',
  });

  const extendedCostTariff = watch('extendedCostTariff');

  return (
    <TableContainer className={C.tableContainer}>
      <Table className={C.table}>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell>{translate('sgw.requests.detail.retributionTable.corrections.table.exempted')}</TableCell>
            <TableCell>
              {!extendedCostTariff
                ? translate('sgw.requests.detail.retributionTable.corrections.table.extendedTariff')
                : ''}
            </TableCell>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell />
            <TableCell className={C.subtitle}>
              {translate('sgw.requests.detail.retributionTable.corrections.table.bruto')}
            </TableCell>
            <TableCell className={C.subtitle}>
              {translate('sgw.requests.detail.retributionTable.corrections.table.surfaceArea')}
            </TableCell>
            <Visible visible={!extendedCostTariff}>
              <TableCell className={C.subtitle}>
                {translate('sgw.requests.detail.retributionTable.corrections.table.duration')}
              </TableCell>
              <TableCell className={C.subtitle}>
                {translate('sgw.requests.detail.retributionTable.corrections.table.size')}
              </TableCell>
            </Visible>
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map((phase, index) => (
            <TableRow>
              <TableCell>
                <div className={classNames(G.flexColumn, phase.parentPhaseId ? G.marginLeft.XS : '')}>
                  <div>
                    {phase.phaseName || `${translate('sgw.requests.detail.retributionTable.phase')} ${index + 1}`}
                  </div>
                  <small>
                    {`${formatDateTimeToDisplayDate(phase.dateFrom)} - ${formatDateTimeToDisplayDate(
                      phase.dateUntil,
                    )} (${durationInDays(phase.dateFrom, phase.dateUntil)})`}
                  </small>
                </div>
              </TableCell>
              <TableCell>
                <div>{`${phases[index].totalSurfaceArea} m2`}</div>
              </TableCell>
              <TableCell>
                <div className={C.inputCell}>
                  <input
                    type="number"
                    min={0}
                    className={C.textField}
                    {...register(`phases.${index}.exemptedSurfaceArea` as const, {
                      valueAsNumber: true,
                      min: { value: 0, message: translate('services.form.largerThanZero') },
                      required: { value: true, message: translate('services.form.required') },
                    })}
                    defaultValue={0}
                  />
                  <div>m2</div>
                </div>
              </TableCell>
              {!extendedCostTariff && (
                <>
                  <TableCell>
                    <div className={C.inputCell}>
                      <input
                        type="number"
                        min={0}
                        className={C.textField}
                        {...register(`phases.${index}.extendedDuration` as const, {
                          valueAsNumber: true,
                          min: { value: 0, message: translate('services.form.largerThanZero') },
                          required: { value: true, message: translate('services.form.required') },
                        })}
                        defaultValue={0}
                      />
                      <div>{translate('days')}</div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={C.inputCell}>
                      <input
                        type="number"
                        min={0}
                        className={C.textField}
                        {...register(`phases.${index}.extendedSurface` as const, {
                          valueAsNumber: true,
                          min: { value: 0, message: translate('services.form.largerThanZero') },
                          max: {
                            value: phases[index].maxSurfaceArea,
                            message: translate('services.form.maxValue'),
                          },
                          required: { value: true, message: translate('services.form.required') },
                        })}
                        defaultValue={0}
                      />
                      <div>m2</div>
                    </div>
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
