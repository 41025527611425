import { useAtom } from 'jotai';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { SgwPhasesActions } from '../../../store/actions/phases.actions';
import { getPhasesWithTotalAndMaxSurfaceArea, getSgwRequest } from '../../../store/selectors/sgwRequest.selectors';
import { SgwRequestActions } from '../../../store/actions';
import { intakeModalAtom } from '../IntakeForm/IntakeForm.component';
import { RetributionCorrectionFormPhaseTable } from '../../organisms';
import { createUseStyles } from 'react-jss';
import { IRetributionCorrectionForm } from '../../../types';
import { CheckboxWithLabel } from '../../../common/components/form-fields/checkboxWithLabel.component';
import { TextField } from '@material-ui/core';
import { translate } from '../../../common/translations/translate';

const useStyles = createUseStyles({
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
  },
  tariffOptions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
});

export const RetributionCorrectionForm: FC<{ sgwRequestId: string }> = ({ sgwRequestId }) => {
  const C = useStyles();
  const sgwRequest = useSelector(getSgwRequest(sgwRequestId));
  const phases = useSelector(getPhasesWithTotalAndMaxSurfaceArea());
  const [, setIntakeModal] = useAtom(intakeModalAtom);
  const form = 'intakeForm';
  const dispatch = useDispatch();

  const formMethods = useForm<IRetributionCorrectionForm>({
    defaultValues: {
      extendedCostTariff: sgwRequest?.extendedCostTariff,
      noRetributuionNeeded: sgwRequest?.noRetributuionNeeded,
      retributionMotivation: sgwRequest?.retributionMotivation,
      phases: phases,
    },
  });

  const { register, handleSubmit, setValue, watch } = formMethods;

  const extendedCostTariff = watch('extendedCostTariff');
  const noRetributionNeeded = watch('noRetributuionNeeded');
  const phaseFormFields = watch('phases');
  const somePhasesHaveValues = phaseFormFields.some(
    ({ exemptedSurfaceArea, extendedSurface, extendedDuration }) =>
      !!exemptedSurfaceArea || !!extendedSurface || !!extendedDuration,
  );
  const [partiallyUnderExtendedCostTariff, setPartiallyUnderExtendedCostTariff] = useState(false);

  const onSubmit = (data: IRetributionCorrectionForm) => {
    dispatch(SgwRequestActions.quarterCost.setLoading(true));
    data.phases.forEach((phase) => {
      dispatch(SgwPhasesActions.save({ phase }));
    });
    dispatch(
      SgwRequestActions.patch({
        id: parseInt(sgwRequestId),
        extendedCostTariff: data.extendedCostTariff,
        noRetributuionNeeded: data.noRetributuionNeeded,
        retributionMotivation: data.retributionMotivation,
      }),
    );
    setIntakeModal(false);
  };

  const onChangePartiallyUnderExtendedCostTariff = (checked: boolean) => {
    setPartiallyUnderExtendedCostTariff(checked);
    if (!checked) {
      phaseFormFields.forEach((_phase, index) => {
        setValue(`phases.${index}.extendedSurface`, 0);
        setValue(`phases.${index}.extendedDuration`, 0);
        setValue(`phases.${index}.exemptedSurfaceArea`, 0);
      });
    }
  };

  useEffect(() => {
    if (somePhasesHaveValues) {
      if (!extendedCostTariff) {
        setPartiallyUnderExtendedCostTariff(true);
        setValue('extendedCostTariff', false);
        setValue('noRetributuionNeeded', false);
      }
    } else {
      setPartiallyUnderExtendedCostTariff(false);
    }
  }, [somePhasesHaveValues, extendedCostTariff, setValue]);

  return (
    <FormProvider {...formMethods}>
      <form id={form} onSubmit={handleSubmit(onSubmit)} noValidate>
        {!noRetributionNeeded && <RetributionCorrectionFormPhaseTable />}
        <h4>{translate('sgw.requests.detail.retributionTable.corrections.tariffOptions')}</h4>
        <div className={C.tariffOptions}>
          <CheckboxWithLabel
            label={translate('sgw.requests.detail.retributionTable.corrections.extendedCostTariff')}
            checked={extendedCostTariff}
            {...register('extendedCostTariff')}
            onChange={(checked: boolean) => setValue('extendedCostTariff', checked)}
            disabled={noRetributionNeeded || partiallyUnderExtendedCostTariff}
          />
          <CheckboxWithLabel
            label={translate('sgw.requests.detail.retributionTable.corrections.partiallyUnderExtendedCostTariff')}
            checked={partiallyUnderExtendedCostTariff}
            onChange={onChangePartiallyUnderExtendedCostTariff}
            disabled={extendedCostTariff || noRetributionNeeded}
          />
          <CheckboxWithLabel
            label={translate('sgw.requests.detail.retributionTable.corrections.noRetributionNeeded')}
            checked={noRetributionNeeded}
            {...register('noRetributuionNeeded')}
            disabled={extendedCostTariff || partiallyUnderExtendedCostTariff}
            onChange={(checked: boolean) => setValue('noRetributuionNeeded', checked)}
          />
          <TextField
            multiline
            {...register('retributionMotivation')}
            label={translate('sgw.requests.detail.retributionTable.corrections.retributionMotivation')}
            placeholder={translate('sgw.requests.detail.retributionTable.corrections.retributionMotivationPlaceholder')}
            fullWidth
          />
        </div>
      </form>
    </FormProvider>
  );
};
