import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { COLORS } from '../../../common';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { Header } from '../../../common/components/layout/Header.component';
import { LabelChip } from '../../../common/components/layout/LabelChip.component';
import { Loader } from '../../../common/components/layout/loader.component';
import { Visible } from '../../../common/components/layout/Visible.component';
import { BACKEND_URL } from '../../../common/config/environment';
import { appUrls } from '../../../common/config/url.constants';
import requestExport from '../../../assets/images/request-export.svg';
import { ServicesActions } from '../../../common/store/services/Services.actions';
import { getService } from '../../../common/store/services/Services.selectors';
import { translate } from '../../../common/translations/translate';
import { linkClick } from '../../../common/utils/link.util';
import { Auth } from '../../../components';
import { selectAccessToken } from '../../../store/selectors';
import { IPincodeManagement, IService, IServiceAclActions, VehicleSource } from '../../../types';
import { PinCodeDetails } from './pincode/PincodeDetails.component';
import { ServiceDetails } from './ServiceDetails.component';
import { WhitelistEntryList } from './WhitelistEntryList.component';

const useStyles = makeStyles({
  container: { marginTop: 20 },
  column: { margin: 10 },
  exportButton: { marginTop: 8 },
  exportIcon: {
    height: '15px',
    marginTop: -1,
    marginRight: 8,
  },
  labelChip: { marginLeft: 10 },
  paper: { marginBottom: 20 },
});

interface IParams {
  id: string;
}

export const ServicesDetailPage: FunctionComponent = () => {
  const dispatch = useDispatch();
  const C = useStyles();
  const { id }: IParams = useParams();
  const service = useSelector(getService(id));
  const token = useSelector(selectAccessToken);

  useEffect(() => {
    dispatch(ServicesActions.fetch(id));
  }, [dispatch, id]);

  const onSave = useCallback(
    (service: IService) => {
      dispatch(ServicesActions.save(service));
    },
    [dispatch],
  );

  const onSavePincode = useCallback(
    (pinCodeManagement: IPincodeManagement) => {
      dispatch(ServicesActions.savePincode({ serviceId: id, pinCodeManagement }));
    },
    [dispatch, id],
  );

  const onResetPincodes = useCallback(() => {
    dispatch(ServicesActions.resetPincodes(id));
  }, [dispatch, id]);

  const onExport = useCallback(() => {
    linkClick(`${BACKEND_URL}/backoffice/v1/white_list_entries/export?serviceId=${service.id}&access_token=${token}`);
  }, [service, token]);

  return (
    <Loader loading={!service}>
      <Header
        backButtonUrl={appUrls.services.base}
        extraHeader={
          <Typography variant="h5" align="right">
            <Auth acl={[IServiceAclActions.exportServiceWhitelist]}>
              <Button.Gray
                dataTestId="exportButton"
                debounceTime={1000}
                onClick={onExport}
                className={C.exportButton}
                startIcon={
                  <img className={C.exportIcon} src={requestExport} alt={translate('services.exportButton')} />
                }
              >
                {translate('services.exportButton')}
              </Button.Gray>
            </Auth>
          </Typography>
        }
      >
        {translate('services.detail.title', { name: service?.name })}
        <Visible visible={service?.vehicleSource === VehicleSource.aptr}>
          <LabelChip className={C.labelChip} label={translate('services.form.vehicleSource.options.aptr')} />
        </Visible>
        <Visible visible={service?.vehicleSource === VehicleSource.whitelist}>
          <LabelChip
            className={C.labelChip}
            color={COLORS.BLUE}
            label={translate('services.form.vehicleSource.options.whitelist')}
          />
        </Visible>
      </Header>
      <Auth acl={[IServiceAclActions.viewService]}>
        <Container className={C.container}>
          <Grid container justify="center" spacing={4}>
            <Grid item xs={6} data-testid="leftColumn">
              <ServiceDetails service={service} onSave={onSave} className={C.paper} />
              <Visible visible={!!service?.syncWithMoov}>
                <PinCodeDetails
                  service={service}
                  onSave={onSavePincode}
                  className={C.paper}
                  onReset={onResetPincodes}
                />
              </Visible>
            </Grid>
            <Grid item xs={6} data-testid="rightColumn">
              <WhitelistEntryList vehicleSource={service?.vehicleSource} />
            </Grid>
          </Grid>
        </Container>
      </Auth>
    </Loader>
  );
};
