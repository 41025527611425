import { translate } from '../common/translations/translate';

export const NOTIFICATION_SOURCE_CPA = 'cpa';
export const NOTIFICATION_SOURCE_CIVILIAN = 'civilian';
export const NOTIFICATION_SOURCE_WERKHAVEN = 'werkhaven';

export enum NotificationState {
  new = 'new',
  in_progress = 'in_progress',
  in_gmp = 'in_gmp',
  invalid = 'invalid',
  pickup = 'pickup',
  pick_up_construction_zone_signage = 'pick_up_construction_zone_signage',
  done = 'done',
  handle_internally = 'handle_internally',
}

export enum NotificationColor {
  new = '#000',
  in_progress = '#256de0',
  in_gmp = '#e18d05',
  invalid = '#c92623',
  pickup = '#c5c5c5',
  done = '#059311',
  handle_internally = '#57d9a3',
}

export function getNotificationStates() {
  return [
    {
      bgcolor: NotificationColor.new,
      id: NotificationState.new,
      name: translate('Notifications.status.new'),
    },
    {
      bgcolor: NotificationColor.in_progress,
      id: NotificationState.in_progress,
      name: translate('Notifications.status.in_progress'),
    },
    {
      bgcolor: NotificationColor.done,
      id: NotificationState.done,
      name: translate('Notifications.status.done'),
    },
    {
      bgcolor: NotificationColor.in_gmp,
      id: NotificationState.in_gmp,
      name: translate('Notifications.status.in_gmp'),
    },
    {
      bgcolor: NotificationColor.pickup,
      id: NotificationState.pickup,
      name: translate('Notifications.status.pickup'),
    },
    {
      bgcolor: NotificationColor.pickup,
      id: NotificationState.pick_up_construction_zone_signage,
      name: translate('Notifications.status.pick_up_construction_zone_signage'),
    },
    {
      bgcolor: NotificationColor.invalid,
      id: NotificationState.invalid,
      name: translate('Notifications.status.invalid'),
    },
    {
      bgcolor: NotificationColor.handle_internally,
      id: NotificationState.handle_internally,
      name: translate('Notifications.status.handle_internally'),
    },
  ];
}

export function getNotificationSources() {
  return [
    {
      id: NOTIFICATION_SOURCE_CPA,
      name: translate('Notifications.Source.CPA'),
    },
    {
      id: NOTIFICATION_SOURCE_CIVILIAN,
      name: translate('Notifications.Source.Civilian'),
    },
    {
      id: NOTIFICATION_SOURCE_WERKHAVEN,
      name: translate('Notifications.Source.Werkhaven'),
    },
  ];
}
