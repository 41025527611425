import { FC } from 'react';
import { useAuthorization } from '../../../hooks';
import { IUserManagementAclActions } from '../../../types';
import * as React from 'react';
import { Visible } from '../../../common/components/layout/Visible.component';

interface IProps {
  name?: string;
  url?: string;
}

export const ViewUserField: FC<IProps> = ({ name, url }) => {
  const { isAuthorized: canViewUser } = useAuthorization(IUserManagementAclActions.viewUser);

  return (
    <Visible visible={canViewUser} defaultComponent={name}>
      <a href={url} target="_blank" rel="noreferrer">
        {name}
      </a>
    </Visible>
  );
};
