export enum Quarter {
  ONE = 1,
  TWO,
  THREE,
  FOUR,
}
export interface ISgwQuarterCost {
  invoiced: boolean;
  quarterYear: number;
  quarterIndex: Quarter;
  quarterCost: number;
  quarterCorectionCost?: number;
  correctionInvoiced?: boolean;
  phases: IPhaseQuarterCost[];
}

export interface IPhaseQuarterCost {
  phaseId: number;
  phaseName: string;
  cost: number;
  correctionInvoiced?: boolean;
  correctionCost?: number;
  duration: number;
  invoiced: boolean;
  surfaceArea50m2: number;
  surfaceArea300m2: number;
  surfaceArea301m2: number;
  surfaceAreaExtended?: number;
  surfaceAreaNormal?: number;
  tariffCategory: TariffCategory;
  tariffs: {
    surfaceArea50m2: number;
    surfaceArea300m2: number;
    surfaceArea301m2: number;
    surfaceAreaExtended?: number;
    surfaceAreaNormal?: number;
  };
}

export enum TariffCategory {
  timeLessThan6 = 'time<6',
  timeInBetween6And12 = '6<time<12',
  timeMoreThan12 = '12<time',
  normal = 'normal',
  extended = 'extended',
}
