import React, { FunctionComponent, useCallback, useState } from 'react';
import I18n from 'i18n-js';
import { IFile, IFileManage, IFileSelect, IRequestAclActions } from '../../../types';
import { Unavailable } from '../formatters/unavailable';
import { FileLink } from './FileLink.component';
import { Button } from '../buttons/asign-button-extensions';
import { CloudDownload, CloudUpload } from '@material-ui/icons';
import { Checkbox } from '../form-fields/checkbox.component';
import { ImageCarousel } from '../../../request/components/overview/ImageCarousel.component';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { getIsAuthorized } from '../../store/user/user.selectors';
import { Thumbnail } from './Thumbnail.component';
import { FileSelect } from './FileSelect.component';

const useStyles = makeStyles({
  button: { margin: '10px 0 0 20px' },
  container: { width: '100%' },
  flexWrapper: { display: 'flex', flexWrap: 'wrap' },
  flexWithSpace: { display: 'flex', justifyContent: 'flex-end' },
  marginRight: { marginRight: 10 },
  selectAll: { verticalAlign: 'middle', marginRight: 5 },
});

export const FileBox: FunctionComponent<IFileManage & IFileSelect> = ({
  accept,
  onDownloadZip,
  onFileSelect,
  onRemoveFile,
  placeholder,
  storedFiles = [],
  uploadedFiles = [],
  disabled = false,
}) => {
  const C = useStyles();
  const [selectedFilesIds, setSelectedFilesIds] = useState<string[]>([]);
  const [downloadSelection, setDownloadSelection] = useState<string[]>([]);
  const [showCarousel, setShowCarousel] = useState<boolean>(false);
  const [carouselIndex, setCarouselIndex] = useState<number>(0);
  const canEditAssignedRequest = useSelector(getIsAuthorized([IRequestAclActions.editAssignedRequest]));

  const _onRemoveFile = (fileName: string) => {
    const removeIds = uploadedFiles.filter(({ uploadId }) => uploadId === fileName).map(({ id }) => id);
    setSelectedFilesIds(selectedFilesIds.filter((id) => !removeIds.includes(id)));
    onRemoveFile && onRemoveFile(fileName);
  };

  const _onFileSelect = (file: IFile) => {
    setSelectedFilesIds(selectedFilesIds.concat(file.metadata.id));
    onFileSelect && onFileSelect(file);
  };

  const _onUpdateDownloadSelection = (id: string) => {
    downloadSelection.includes(id)
      ? setDownloadSelection(downloadSelection.filter((i) => i !== id))
      : setDownloadSelection(downloadSelection.concat(id));
  };

  const _onDownloadZip = useCallback(() => {
    onDownloadZip && onDownloadZip(downloadSelection);
  }, [downloadSelection, onDownloadZip]);

  const getStoredFilesIds = useCallback(() => storedFiles.map(({ file }) => file), [storedFiles]);

  const getUploadedFiles = useCallback(
    () =>
      uploadedFiles
        .filter(({ id }) => selectedFilesIds.includes(id))
        .filter(({ uploadId }) => !uploadId || !getStoredFilesIds().includes(uploadId)),
    [getStoredFilesIds, selectedFilesIds, uploadedFiles],
  );

  const getStoredImages = useCallback(
    () => storedFiles.filter(({ mimeType }) => mimeType?.includes('image')),
    [storedFiles],
  );

  const getStoredFiles = useCallback(
    () => storedFiles.filter(({ mimeType }) => !mimeType?.includes('image')),
    [storedFiles],
  );

  const onDownloadAllFiles = useCallback(() => {
    setDownloadSelection(downloadSelection.length === storedFiles.length ? [] : storedFiles.map(({ id }) => `${id}`));
  }, [downloadSelection.length, storedFiles]);

  const _onOpenCarousel = (index: number) => () => {
    setCarouselIndex(index);
    setShowCarousel(true);
  };

  const _onCloseCarousel = () => {
    setShowCarousel(false);
    setCarouselIndex(0);
  };

  return (
    <div className={C.container} data-testid="FileBox">
      <div>
        {!storedFiles.length && !getUploadedFiles().length && placeholder ? <Unavailable text={placeholder} /> : null}
        {getUploadedFiles().map((file) => (
          <FileLink
            key={file.id}
            {...file}
            onRemove={!!onRemoveFile && canEditAssignedRequest ? _onRemoveFile : undefined}
            removeByField={'id'}
          />
        ))}
      </div>
      {storedFiles.length > 1 && onDownloadZip && (
        <div>
          <Checkbox
            checked={downloadSelection.length === storedFiles.length}
            className={C.selectAll}
            onClick={onDownloadAllFiles}
          />
          <span>{I18n.translate('FileBox.selectAll')}</span>
        </div>
      )}
      <div className={C.flexWrapper}>
        {getStoredImages().map((file, index) => (
          <Thumbnail
            file={file}
            key={file.id}
            onClick={_onOpenCarousel(index)}
            onRemove={!!onRemoveFile && canEditAssignedRequest ? _onRemoveFile : undefined}
            onSelect={!!onDownloadZip ? _onUpdateDownloadSelection : undefined}
            selected={downloadSelection.includes(`${file.id}`)}
          />
        ))}
        <ImageCarousel
          images={getStoredImages()}
          index={carouselIndex}
          onClose={_onCloseCarousel}
          visible={showCarousel}
        />
      </div>
      <div className={C.flexWrapper}>
        {getStoredFiles().map((file) => (
          <FileLink
            key={file.id}
            {...file}
            onSelect={!!onDownloadZip ? _onUpdateDownloadSelection : undefined}
            selected={downloadSelection.includes(`${file.id}`)}
            onRemove={!!onRemoveFile && canEditAssignedRequest ? _onRemoveFile : undefined}
            removeByField={'fileName'}
          />
        ))}
      </div>
      <div className={C.flexWithSpace}>
        {onDownloadZip && (
          <Button.Blue
            className={C.button}
            dataTestId="DownloadButton"
            disabled={downloadSelection.length === 0}
            onClick={_onDownloadZip}
          >
            <CloudDownload className={C.marginRight} />
            <span>{I18n.translate('FileBox.downloadButton')}</span>
          </Button.Blue>
        )}
        {canEditAssignedRequest && onFileSelect && (
          <FileSelect.BlueButton accept={accept} onFileSelect={_onFileSelect} className={C.button} disabled={disabled}>
            <CloudUpload className={C.marginRight} />
            <span>{I18n.translate('FileBox.uploadButton')}</span>
          </FileSelect.BlueButton>
        )}
      </div>
    </div>
  );
};
