import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequestLocationsActions } from '../store/actions';
import { selectRequestSuggestions } from '../store/selectors';
import { ANTWERP } from '../common/config/app.constants';

export const useStreetSuggestions = (street: string, zipCode?: number, city?: string) => {
  const dispatch = useDispatch();
  const suggestions = useSelector(selectRequestSuggestions);

  useEffect(() => {
    dispatch(
      RequestLocationsActions.fetchSuggestions({
        city: !city && !zipCode ? ANTWERP : city,
        street,
        zipCode,
      }),
    );
  }, [dispatch, city, street, zipCode]);

  return suggestions;
};
