// HTTP request over the proxy in webpack are really slow, therefor we don't proxy request in dev mode.
import asign_logo from '../../assets/images/a-logo.svg';
import { BACKEND_URL, FRONT_URL } from './environment';
import { latLngBounds } from 'leaflet';
import { isInIframe } from '../utils/window.util';

export const backendUrl = BACKEND_URL;
export const frontUrl = FRONT_URL;
export const baseUrl = isInIframe() ? backendUrl : frontUrl;

export const CONFIG = {
  arcgisUrl: 'https://geodata.antwerpen.be/arcgissql/rest/services/P_ASign/ASign/MapServer',
  backOfficeApi: `${backendUrl}/backoffice`,
  baseGoogleMapsUrl: 'https://www.google.be/maps/place/',
  baseMapUrl: 'https://tiles.arcgis.com/tiles/1KSVSmnHT2Lw9ea6/arcgis/rest/services/basemap_stadsplan_v6/MapServer',
  baseViodUrl: 'https://geocortex.antwerpen.be/Html5Viewer/?viewer=viod',
  defaultMapBounds: latLngBounds([51.188751, 4.376464], [51.237473, 4.453095]),
  defaultMapLocation: {
    center: [51.2179465, 4.4044943] as [number, number],
    zoom: 13,
  },
  deLijnMailAddress: 'omleidingenStad.ANT@delijn.be',
  frontUrl,
  generalApi: `${backendUrl}/general`,
  logoUrl: asign_logo,
  tokenUrl: `${backendUrl}/oauth/v2/token`,
  logoutUrl: `${backendUrl}/oauth/v2/logoutUrl`,
  oauthTokenUrl: `${backendUrl}/oauthAdmin`,
  openStreetMapUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
};
