import { Tooltip } from '@material-ui/core';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { ConflictCategory, IWithClassName, IWithStyle, SgwPartnerType } from '../../../types';
import { translate, translateIgnoreTS } from '../../translations/translate';
import assign_user from '../../../assets/images/assign-user.svg';
import assign_user_inverted from '../../../assets/images/assign-user-inverted.svg';
import copy_icon from '../../../assets/images/icon-copy.svg';
import google_maps from '../../../assets/images/google-maps-icon.svg';
import handle_internally from '../../../assets/images/handle-internally.svg';
import history from '../../../assets/images/history.svg';
import logout from '../../../assets/images/logout.svg';
import map_marker from '../../../assets/images/map-marker.svg';
import reset_pincode from '../../../assets/images/reset-pincode.svg';
import unassign_user from '../../../assets/images/unassign-user.svg';
import conflicts from '../../../assets/images/conflicts.svg';
import info from '../../../assets/images/info.svg';
import partner_requestor from '../../../assets/images/partner-requestor.svg';
import partner_client from '../../../assets/images/partner-client.svg';
import partner_fee from '../../../assets/images/partner-fee.svg';
import partner_contractor from '../../../assets/images/partner-contractor.svg';
import partner_signalisation from '../../../assets/images/partner-signalisation.svg';
import conflict_generic from '../../../assets/images/conflict.generic.svg';
import conflict_bench from '../../../assets/images/conflict.bench.svg';
import conflict_bus from '../../../assets/images/conflict.bus.svg';
import conflict_calender from '../../../assets/images/conflict.calendar.svg';
import conflict_change from '../../../assets/images/conflict.change.svg';
import conflict_crane from '../../../assets/images/conflict.crane.svg';
import conflict_parking from '../../../assets/images/conflict.parking.svg';
import conflict_port from '../../../assets/images/conflict.port.svg';
import conflict_road_closure from '../../../assets/images/conflict.road-closure.svg';
import conflict_train from '../../../assets/images/conflict.train.svg';
import conflict_tree from '../../../assets/images/conflict.tree.svg';
import conflict_prohibited from '../../../assets/images/conflict.unavailable.svg';
import conflict_waste from '../../../assets/images/conflict.waste.svg';
import conflict_email from '../../../assets/images/conflict.email.svg';
import conflict_flag from '../../../assets/images/conflict.flag.svg';
import conflict_detour from '../../../assets/images/conflict.detour.svg';
import conflict_trafficLight from '../../../assets/images/conflict.trafficLight.svg';
import feedback_given from '../../../assets/images/lamp.svg';
import email from '../../../assets/images/email.svg';
import download from '../../../assets/images/download.svg';

interface IProps extends IWithClassName, IWithStyle, FunctionComponent<React.ImgHTMLAttributes<HTMLElement>> {
  alt?: string;
  variant?: 'normal' | 'large';
}

interface IPartnerProps extends Partial<IProps> {
  partnerType: SgwPartnerType;
}
interface IConflictProps extends IWithClassName {
  variant: ConflictCategory;
  alt: string;
}

const partnerIcons = {
  [SgwPartnerType.client]: partner_client,
  [SgwPartnerType.contractor]: partner_contractor,
  [SgwPartnerType.fee]: partner_fee,
  [SgwPartnerType.requester]: partner_requestor,
  [SgwPartnerType.signalisation]: partner_signalisation,
};
const conflictIcons = {
  [ConflictCategory.crane]: conflict_crane,
  [ConflictCategory.constructionZone]: conflict_road_closure,
  [ConflictCategory.parkingBan]: conflict_prohibited,
  [ConflictCategory.event]: conflict_calender,
  [ConflictCategory.publicTransport]: conflict_bus,
  [ConflictCategory.cityCleaning]: conflict_waste,
  [ConflictCategory.dedicatedParkingCambio]: conflict_parking,
  [ConflictCategory.dedicatedParkingCarSharing]: conflict_parking,
  [ConflictCategory.dedicatedParkingChargingStation]: conflict_parking,
  [ConflictCategory.dedicatedParkingDisabledPerson]: conflict_parking,
  [ConflictCategory.dedicatedParkingTaxi]: conflict_parking,
  [ConflictCategory.greenZone]: conflict_tree,
  [ConflictCategory.mobility]: conflict_change,
  [ConflictCategory.portZone]: conflict_port,
  [ConflictCategory.railway]: conflict_train,
  [ConflictCategory.streetInfrastructure]: conflict_bench,
  [ConflictCategory.detour]: conflict_detour,
  [ConflictCategory.fireDepartment]: conflict_generic,
  [ConflictCategory.utilityCompanies]: conflict_generic,
  [ConflictCategory.shippingPolice]: conflict_generic,
  [ConflictCategory.cityHarbour]: conflict_generic,
  [ConflictCategory.trafficPolice]: conflict_generic,
  [ConflictCategory.projectSites]: conflict_generic,
  [ConflictCategory.tunnel]: conflict_generic,
  [ConflictCategory.markets]: conflict_generic,
  [ConflictCategory.trafficSignaling]: conflict_generic,
  [ConflictCategory.brabantsTunnel]: conflict_generic,
  [ConflictCategory.neighborhoodParking]: conflict_generic,
  [ConflictCategory.zoo]: conflict_generic,
  [ConflictCategory.retailCatering]: conflict_generic,
  [ConflictCategory.prison]: conflict_generic,
  [ConflictCategory.railwayInfrastructure]: conflict_generic,
  [ConflictCategory.artWork]: conflict_generic,
  [ConflictCategory.loadingZone]: conflict_generic,
  [ConflictCategory.airport]: conflict_generic,
  [ConflictCategory.oosterweelZone]: conflict_generic,
  [ConflictCategory.parkingMachine]: conflict_generic,
  [ConflictCategory.highway]: conflict_generic,
  [ConflictCategory.diamantwijk]: conflict_generic,
  [ConflictCategory.publicOrder]: conflict_generic,
  [ConflictCategory.football]: conflict_generic,
  [ConflictCategory.railroadPolice]: conflict_generic,
  [ConflictCategory.velo]: conflict_generic,
  [ConflictCategory.streetLightning]: conflict_generic,
  [ConflictCategory.terrace]: conflict_generic,
  [ConflictCategory.trafficLightsAWV]: conflict_trafficLight,
  [ConflictCategory.temporaryTrafficLights]: conflict_generic,
  [ConflictCategory.garbageCans]: conflict_generic,
};

const IconComp: IProps = (props, variant = 'normal') => {
  const height = variant === 'normal' ? '15px' : '20px';

  return <img alt={props.alt || ''} style={{ height }} {...props} />;
};

export const Icon = {
  AssignUser: (props: Partial<IProps>) => (
    <IconComp src={assign_user} alt={props.alt || translate('icons.alt.assignUser')} {...props} />
  ),
  AssignUserInverted: (props: Partial<IProps>) => (
    <IconComp src={assign_user_inverted} alt={props.alt || translate('icons.alt.assignUser')} {...props} />
  ),
  CopyRequest: (props: Partial<IProps>) => (
    <IconComp src={copy_icon} alt={props.alt || translate('icons.alt.copyRequest')} {...props} />
  ),
  GoogleMaps: (props: Partial<IProps>) => (
    <IconComp src={google_maps} alt={props.alt || translate('icons.alt.googleMaps')} {...props} />
  ),
  HandleInternally: (props: Partial<IProps>) => (
    <IconComp
      src={handle_internally}
      alt={props.alt || translate('icons.alt.handleInternally')}
      {...props}
      style={{ width: '23px' }}
    />
  ),
  History: (props: Partial<IProps>) => (
    <IconComp src={history} alt={props.alt || translate('icons.alt.history')} {...props} />
  ),
  Logout: (props: Partial<IProps>) => (
    <IconComp src={logout} alt={props.alt || translate('icons.alt.logout')} {...props} />
  ),
  ResetPincode: (props: Partial<IProps>) => (
    <IconComp src={reset_pincode} alt={props.alt || translate('icons.alt.resetPincode')} {...props} />
  ),
  UnassignUser: (props: Partial<IProps>) => (
    <IconComp src={unassign_user} alt={props.alt || translate('icons.alt.unassignUser')} {...props} />
  ),
  Conflicts: (props: Partial<IProps>) => (
    <Tooltip title={translate('sgw.requests.dashboard.table.conflicts')}>
      <span>
        <IconComp src={conflicts} alt={props.alt || translate('icons.alt.conflicts')} {...props} />
      </span>
    </Tooltip>
  ),
  FeedbackGiven: (props: Partial<IProps>) => (
    <Tooltip title={translate('sgw.requests.dashboard.table.feedbackGiven')}>
      <span>
        <IconComp src={feedback_given} alt={props.alt || translate('icons.alt.feedbackGiven')} {...props} />
      </span>
    </Tooltip>
  ),
  Info: (props: Partial<IProps>) => <IconComp src={info} alt={props.alt || translate('icons.alt.info')} {...props} />,
  Partner: ({ alt, partnerType, ...props }: IPartnerProps) => (
    <Tooltip title={translateIgnoreTS(`sgw.partners.types.${partnerType}`)}>
      <span>
        <IconComp
          src={partnerIcons[partnerType]}
          alt={alt || translateIgnoreTS(`sgw.partners.types.${partnerType}`)}
          {...props}
        />
      </span>
    </Tooltip>
  ),
  ConflictIcons: ({ variant, alt, ...props }: IConflictProps) => (
    <img src={conflictIcons[variant]} alt={alt} {...props} />
  ),
  ConflictFlag: ({ variant, ...props }: Partial<IConflictProps>) => (
    <img src={conflict_flag} alt={props.alt || translate('assets.images.conflictFlag')} {...props} />
  ),
  MapMarker: ({ alt, ...props }: Partial<IProps>) => <img src={map_marker} alt={alt} {...props} />,

  SendEmail: (props: Partial<IProps>) => (
    <img src={conflict_email} alt={props.alt || translate('assets.images.conflicts.sendEmail')} {...props} />
  ),
  Email: (props: Partial<IProps>) => (
    <IconComp src={email} alt={props.alt || translate('assets.images.email')} {...props} />
  ),
  Download: (props: Partial<IProps>) => (
    <IconComp src={download} alt={props.alt || translate('assets.images.download')} {...props} />
  ),
};
