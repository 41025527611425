import axios from 'axios';
import { CANCEL } from 'redux-saga';
import { CONFIG, IApiPromise } from '../../common';
import { ITeam, IWorkOrder, IWorkOrderItem, IWorkOrderItemsFilter } from '../../types';
import { WorkOrderItemTypes } from '../planning.constants';

export class PlanningApi {
  public static getTeams(): IApiPromise<ITeam[]> {
    return axios.get(`${CONFIG.backOfficeApi}/v1/teams`);
  }

  public static getWorkOrders(filter: IWorkOrderItemsFilter, pageSize: number = 1): IApiPromise<IWorkOrder[]> {
    const source = axios.CancelToken.source();
    const { date } = filter;
    const request: any = axios.get(`${CONFIG.backOfficeApi}/v1/work_orders`, {
      cancelToken: source.token,
      params: {
        date,
        page_size: pageSize,
      },
    });
    request[CANCEL] = () => source.cancel();
    return request;
  }

  public static getWorkOrderItems(
    workOrderItemsFilter: IWorkOrderItemsFilter,
    pageSize: number = 1,
  ): IApiPromise<IWorkOrderItem[]> {
    const source = axios.CancelToken.source();
    const { types } = workOrderItemsFilter;
    const filteredTypes = types.includes(WorkOrderItemTypes.pick_up)
      ? types.filter((type) => type !== WorkOrderItemTypes.pickup_overdue)
      : types;

    const request: any = axios.get(`${CONFIG.backOfficeApi}/v1/work_order_items_to_planning_map`, {
      cancelToken: source.token,
      params: {
        page_size: pageSize,
        ...workOrderItemsFilter,
        types: filteredTypes,
      },
    });
    // Cancel the request when the saga is cancelled
    request[CANCEL] = () => source.cancel();
    return request;
  }

  public static getWorkOrderItem(id: number): IApiPromise<IWorkOrderItem> {
    const source = axios.CancelToken.source();
    const request: any = axios.get(`${CONFIG.backOfficeApi}/v1/work_order_items/${id}`, {
      cancelToken: source.token,
    });
    // Cancel the request when the saga is cancelled
    request[CANCEL] = () => source.cancel();
    return request;
  }

  public static deleteWorkOrderItem(id: number): IApiPromise<IWorkOrderItem[]> {
    const source = axios.CancelToken.source();
    const request: any = axios.delete(`${CONFIG.backOfficeApi}/v1/work_order_items/${id}`, {
      cancelToken: source.token,
    });
    // Cancel the request when the saga is cancelled
    request[CANCEL] = () => source.cancel();
    return request;
  }

  public static assignItemsToWorkOrder(workOrder: IWorkOrder): IApiPromise<IWorkOrderItem[]> {
    const source = axios.CancelToken.source();
    const { date } = workOrder;
    const workOrderItems = workOrder.workOrderItems!.map((item) => {
      return item.id;
    });
    const request: any = workOrder.id
      ? axios.patch(`${CONFIG.backOfficeApi}/v1/work_orders/${workOrder.id}`, { workOrderItems })
      : axios.post(`${CONFIG.backOfficeApi}/v1/work_orders`, { date, workOrderItems, team: workOrder.team.id });
    // Cancel the request when the saga is cancelled
    request[CANCEL] = () => source.cancel();
    return request;
  }

  public static markWarningAsIncomplete(id: number): IApiPromise<IWorkOrderItem> {
    return axios.patch(`${CONFIG.backOfficeApi}/v1/work_order_items/${id}`, {
      isWarningRemoved: true,
    } as Partial<IWorkOrderItem>);
  }
}
