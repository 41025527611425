import { Tooltip } from '@material-ui/core';
import * as React from 'react';
import urgentBlack from '../../../assets/images/icon-urgent-black.svg';
import urgent from '../../../assets/images/icon-urgent.svg';
import { translate } from '../../../common/translations/translate';
import { IWithClassName } from '../../../types';

interface IProps extends IWithClassName {
  isHeader?: boolean;
}

export const UrgentIcon = ({ isHeader, ...props }: IProps) => (
  <Tooltip title={translate('emergencyProcedure')} aria-label="urgent">
    <img
      style={{ height: '15px' }}
      src={isHeader ? urgentBlack : urgent}
      alt={translate('emergencyProcedure')}
      {...props}
    />
  </Tooltip>
);

UrgentIcon.Header = () => <UrgentIcon isHeader />;
