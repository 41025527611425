import { createSelector } from '@reduxjs/toolkit';
import { IRootState } from '../../../root.state';
import { CarFreeZoneType, DateFormat, IRequestOverview, IRequestData, RequestStates, GateSas } from '../../../types';
import { createAllEntitiesSelector } from '../../utils/normalized.util';
import { getMoovTemplatesAll } from '../moov/moov.selectors';
import { createPagedApiParamsSelector } from '../utils/generic.selectors';
import * as I18n from 'i18n-js';
import moment from 'moment';
import { selectRequestById } from '../../../store/selectors';

export const selectCarFreeZones = (store: IRootState) => store.carFreeZones.carFreeZones || [];
export const selectAllIds = (store: IRootState) => store.carFreeZones.list.allIds;
export const selectById = (store: IRootState) => store.carFreeZones.list.byId;
export const selectTable = (store: IRootState) => store.carFreeZones.list.table;

export const selectCarFreeZonesLoading = (store: IRootState) => store.carFreeZones.list.loading;
export const selectCarFreeZoneTemplates = (store: IRootState) => store.carFreeZones.templates;

export const selectRequestTable = (store: IRootState) => store.carFreeZones.requests.list.table;
export const selectRequestsLoading = (store: IRootState) => store.carFreeZones.requests.list.loading;
export const selectRequestsAllIds = (store: IRootState) => store.carFreeZones.requests.list.allIds;

export const selectGatesIsSyncing = (store: IRootState) => store.carFreeZones.gates.isSyncing;
export const selectGatesTable = (store: IRootState) => store.carFreeZones.gates.list.table;
export const selectGatesAllIds = (store: IRootState) => store.carFreeZones.gates.list.allIds;
export const selectGatesById = (store: IRootState) => store.carFreeZones.gates.list.byId;

export const selectExemptionsIsSyncing = (store: IRootState) => store.carFreeZones.exemptions.isSyncing;
export const selectExemptionsTable = (store: IRootState) => store.carFreeZones.exemptions.list.table;
export const selectExemptionsAllIds = (store: IRootState) => store.carFreeZones.exemptions.list.allIds;
export const selectExemptionsById = (store: IRootState) => store.carFreeZones.exemptions.list.byId;
export const selectExemptionsLoading = (store: IRootState) => store.carFreeZones.exemptions.list.loading;

const getCarFreeZonesSorted = createSelector([selectCarFreeZones], (carFreeZones) =>
  [...carFreeZones].sort((cfz1, cfz2) => {
    // Move CarFreeZoneType.limited on the top of the list
    if (cfz2.type === CarFreeZoneType.limited && cfz1.type !== cfz2.type) {
      return 1;
    } else if (cfz1.type === CarFreeZoneType.limited && cfz1.type !== cfz2.type) {
      return -1;
    }
    return `${cfz1.name}`.localeCompare(cfz2.name);
  }),
);

export const getCarFreeZonesMenuItemsBase = createSelector([getCarFreeZonesSorted], (carFreeZones) =>
  carFreeZones.map(({ id, name, active }) => ({
    active,
    label: active ? name : I18n.t('Requests.Detail.Locations.Info.CarFreeZoneInactive', { name }),
    value: id,
  })),
);

export const getCarFreeZonesMenuItems = (currentId?: number) =>
  createSelector([getCarFreeZonesMenuItemsBase], (carFreeZones) =>
    carFreeZones.filter(({ active, value }) => active || value === currentId),
  );

export const getList = createAllEntitiesSelector(selectAllIds, selectById);
export const getRequestList = createAllEntitiesSelector(selectRequestsAllIds, selectRequestById);
export const getGateList = createAllEntitiesSelector(selectGatesAllIds, selectGatesById);
export const getExemptionList = createAllEntitiesSelector(selectExemptionsAllIds, selectExemptionsById);
export const getGates = createAllEntitiesSelector(selectGatesAllIds, selectGatesById);

export const getRequestListWithAddedFields = createSelector(
  [getRequestList],
  (requests: IRequestOverview[]): IRequestData[] =>
    requests.map((request) => {
      const types = request.publicDomainIntakes?.map((intake) => intake.type.name) || [];
      const fullAddresses =
        request.publicDomainIntakes?.map(
          (intake) => `${intake.street} ${intake.streetNumberFrom}-${intake.streetNumberTo}`,
        ) || [];

      return {
        ...request,
        types,
        fullAddresses,
      };
    }),
);

export const getHasActiveRequests = createSelector([getRequestListWithAddedFields], (requests: IRequestOverview[]) =>
  requests.some(
    ({ state }) => ![RequestStates.closed, RequestStates.rejected, RequestStates.canceled].includes(state.state),
  ),
);

export const getPagedApiParams = createPagedApiParamsSelector(selectTable);
export const getRequestListPagedApiParams = createPagedApiParamsSelector(selectRequestTable);
export const getGateListPagedApiParams = createPagedApiParamsSelector(selectGatesTable);
export const getExemptionListPagedApiParams = createPagedApiParamsSelector(selectExemptionsTable);

export const getAllCarFreeZonesWithGisId = createSelector([selectCarFreeZones], (zones) =>
  zones.filter(({ gisId }) => !!gisId),
);

export const getCarFreeZone = (id: string) => createSelector([selectById], (byId) => byId[id]);
export const getCarFreeZoneLinkedTemplates = (id: string) =>
  createSelector([selectCarFreeZoneTemplates], (byId) => byId[id] || []);

const getCurrentTemplateIds = (zoneId: string) =>
  createSelector([getCarFreeZoneLinkedTemplates(zoneId)], (templates) => templates.map(({ id }) => `${id}`));

export const getCarFreeZoneUnlinkedTemplates = (zoneId: string) =>
  createSelector([getMoovTemplatesAll, getCurrentTemplateIds(zoneId)], (all, currents) =>
    all.filter(({ id }) => !currents.includes(`${id}`)).map(({ id, name }) => ({ value: `${id}`, label: name })),
  );

export const getGatesSyncedAt = (id: string) =>
  createSelector([getCarFreeZone(id)], (carFreeZone) =>
    carFreeZone?.gatesSyncedAt ? moment(carFreeZone.gatesSyncedAt).format(DateFormat.dateTime) : '/',
  );

export const getGatesByType = (selectedSas: GateSas) =>
  createSelector([getGates], (gates) => gates?.filter(({ sas }) => [selectedSas, GateSas.inOut].includes(sas)) || []);
