export enum AppFeatureFlag {
  integrateDigipolisLogin = 'integrateDigipolisLogin',
  integrateDigipolisEventHandler = 'integrateDigipolisEventHandler',
  integrateDigipolisCrsO = 'integrateDigipolisCrsO',
  integrateDigipolisCrsP = 'integrateDigipolisCrsP',
  integrateDigipolisCrsPNotValidated = 'integrateDigipolisCrsPNotValidated',
  integrateDigipolisGfp = 'integrateDigipolisGfp',
  integrateDigipolisMonitorPortal = 'integrateDigipolisMonitorPortal',
  integrateDigipolisSalesIntegration = 'integrateDigipolisSalesIntegration',
  integrateDigipolisAptr = 'integrateDigipolisAptr',
  integrateDigipolisZoneguard = 'integrateDigipolisZoneguard',
  integrateDigipolisChat = 'integrateDigipolisChat',
  integrateDigipolisGmp = 'integrateDigipolisGmp',
  integrateDigipolisArcGis = 'integrateDigipolisArcGis',
  integrateDigipolisParkeerBeheer = 'integrateDigipolisParkeerBeheer',
  retributeUtilityWorks = 'retributeUtilityWorks',
  sentryLoggingBackoffice = 'sentryLoggingBackoffice',
}

export type AppFeatures = {
  [key in AppFeatureFlag]: boolean;
};
