import { useEffect } from 'react';
import { CarFreeZonesActions } from '../common/store/car-free-zones/carFreeZones.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getGatesByType } from '../common/store/car-free-zones/carFreeZones.selectors';
import { GateSas } from '../types';

export const useGates = (carFreeZoneId: number | undefined) => {
  const dispatch = useDispatch();
  const entrances = useSelector(getGatesByType(GateSas.in));
  const exits = useSelector(getGatesByType(GateSas.out));

  useEffect(() => {
    if (carFreeZoneId) {
      dispatch(
        CarFreeZonesActions.gates.list.fetch({
          filters: { carFreeZoneId: `${carFreeZoneId}` },
        }),
      );
    }
  }, [dispatch, carFreeZoneId]);

  return {
    entrances,
    exits,
  };
};
