import { Position } from 'geojson';
import * as Leaflet from 'leaflet';
import { LatLngLiteral } from 'leaflet';
import * as React from 'react';
import { FC, useCallback, useRef } from 'react';
import { FeatureGroup } from 'react-leaflet';
import * as L from 'leaflet';
// @ts-ignore
import { EditControl } from 'react-leaflet-draw';
import { IPlanningAclActions, IWorkOrderItem, IWorkOrderItemAclActions } from '../../types';
import { Auth } from '../../components';
import { useAuthorization } from '../../hooks';
import { WorkOrderItemTypes } from '../planning.constants';
import { WorkOrderItemMarker } from './WorkOrderItemMarker.component';

interface IProps {
  deleteSign(woi: IWorkOrderItem): void;
  onWorkOrderItemsAssign(workOrderItem: IWorkOrderItem[]): void;
  workOrderItems: IWorkOrderItem[];
}

export const WorkOrderItemMarkers: FC<IProps> = ({ deleteSign, onWorkOrderItemsAssign, workOrderItems }) => {
  const featureRef = useRef<L.FeatureGroup>(null);
  const { isAuthorized: canAssignWorkOrderItem } = useAuthorization(
    [IPlanningAclActions.editPlanning, IWorkOrderItemAclActions.editWorkOrderItem],
    true,
  );

  const getWorkOrderItemPosition = useCallback((woi: IWorkOrderItem): LatLngLiteral => {
    let position: Position = [0, 0];
    if (woi.parkingBanIntake && woi.parkingBanIntake.geometry) {
      if (woi.parkingBanIntake.geometry.type === 'Polygon') {
        position = woi.parkingBanIntake.geometry.coordinates[0][0] as Position;
      } else {
        position = woi.parkingBanIntake.geometry.coordinates[0] as Position;
      }
    } else if (woi.type !== WorkOrderItemTypes.retrieve && woi.sign && woi.sign.location) {
      position = woi.sign.location.coordinates as Position;
    } else if (woi.location && woi.location.type === 'Point') {
      position = woi.location.coordinates as Position;
    }
    return { lat: position[1], lng: position[0] };
  }, []);

  const getMarkerLatLng = useCallback(
    (woi: IWorkOrderItem): Leaflet.LatLng => {
      const marker = getWorkOrderItemPosition(woi);
      return new Leaflet.LatLng(marker.lat, marker.lng);
    },
    [getWorkOrderItemPosition],
  );

  const onMarkersSelect = useCallback(
    (event: any) => {
      let selectedMarkers: IWorkOrderItem[] = [];

      if (event.layerType === 'circle') {
        const center = event.layer.getLatLng();
        const radius = event.layer.getRadius();
        selectedMarkers = workOrderItems.filter((woi) => {
          return getMarkerLatLng(woi).distanceTo(center) < radius;
        });
      } else if (event.layerType === 'rectangle') {
        const bounds = event.layer.getBounds();
        selectedMarkers = workOrderItems.filter((woi) => {
          return bounds.contains(getMarkerLatLng(woi));
        });
      }
      canAssignWorkOrderItem && onWorkOrderItemsAssign(selectedMarkers);
      featureRef.current?.clearLayers();
    },
    [getMarkerLatLng, onWorkOrderItemsAssign, workOrderItems, canAssignWorkOrderItem],
  );

  return (
    <>
      <FeatureGroup ref={featureRef}>
        <Auth acl={IPlanningAclActions.editPlanning}>
          <EditControl
            position="topleft"
            onCreated={onMarkersSelect}
            draw={{
              circle: true,
              circlemarker: false,
              marker: false,
              polygon: false,
              polyline: false,
              rectangle: true,
            }}
            edit={{
              edit: false,
              remove: false,
            }}
          />
        </Auth>
      </FeatureGroup>

      {workOrderItems.map((woi) => (
        <WorkOrderItemMarker
          onDeleteSign={() => deleteSign(woi)}
          onDoubleClick={() => onWorkOrderItemsAssign([woi])}
          woi={woi}
        />
      ))}
    </>
  );
};
