import SyncIcon from '@material-ui/icons/Sync';
import { Button } from './asign-button-extensions';
import * as React from 'react';
import { IAsignButtonProps } from '../../../types';
import { FC, PropsWithChildren, useCallback } from 'react';
import { Loader } from '../layout/loader.component';

interface IProps extends IAsignButtonProps {
  isSyncing: boolean;
}

export const SyncButton: FC<PropsWithChildren<IProps>> = ({ isSyncing, onClick, children, ...props }) => {
  const _onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      !isSyncing && onClick?.(e);
    },
    [isSyncing, onClick],
  );

  return (
    <Button.Green
      startIcon={
        <Loader size="icon" loading={isSyncing}>
          <SyncIcon fontSize="small" />
        </Loader>
      }
      {...props}
      onClick={_onClick}
    >
      {children}
    </Button.Green>
  );
};
