import { createSelector } from '@reduxjs/toolkit';
import { createPagedApiParamsSelector } from '../../common/store/utils/generic.selectors';
import { createAllEntitiesSelector } from '../../common/utils/normalized.util';
import { IRootState } from '../../root.state';
import {
  ConflictCategory,
  DateFormat,
  GeoDrawingType,
  IByPartnerType,
  ISgwGeoDrawing,
  ISgwPhase,
  ISgwRequest,
  ISgwRequestAttachment,
  ISgwRequestOverview,
  SgwPartnerType,
  SgwPermitConditionCategory,
  SgwRequestState,
} from '../../types';
import { translate, translateIgnoreTS } from '../../common/translations/translate';
import { getBoundsOfGeometries, getCenterOfGeometries } from '../../common/utils/geojson.util';
import { initialRequestsState, sortById } from '../../common';
import { selectUser } from './request.selectors';
import { conflictGroupSorting } from '../../utils/sorting.util';
import { allQuartersBetweenDates, highestDate, lowestDate } from '../../common/utils/date.util';
import { stringQuarter } from '../../common/utils/quarter.util';
import { getValuesOfEnum } from '../../utils';
import { IPhaseQuarterCost } from '../../types/SgwQuarterCost.types';
import moment from 'moment';

export const selectSgwRequests = (store: IRootState) => store.sgw.requests;
export const selectAllIds = (store: IRootState) => store.sgw.requests.list.allIds;
export const selectById = (store: IRootState) => store.sgw.requests.list.byId;
export const selectTable = (store: IRootState) => store.sgw.requests.list.table;
export const selectListLoading = (store: IRootState) => store.sgw.requests.list.loading;
export const selectCounters = (store: IRootState) => store.sgw.requests.counters;
export const selectSgwRequest = (store: IRootState) => store.sgw.requests.request;
export const selectSgwRequestAcl = (store: IRootState) => store.sgw.requests.request?.acl;

export const selectLoading = (store: IRootState) => store.sgw.requests.loading;
export const selectPhases = (store: IRootState) => store.sgw.requests.phases;
export const selectAttachments = (store: IRootState) => store.sgw.requests.attachments;
export const selectAttachmentsLoading = (store: IRootState) => store.sgw.requests.attachmentsLoading;
export const selectSgwRequestPermitConditions = (store: IRootState) => store.sgw.requests.request?.permitConditions;
export const selectConflictGroups = (state: IRootState) => state.sgw.requests.conflicts.groups;
export const selectConflictGroupMessagesById = (store: IRootState) => store.sgw.requests.conflicts.messages.byId;
export const selectSgwQuarterCosts = (store: IRootState) => store.sgw.requests.quarterCost.costs;
export const selectSgwQuarterCostsLoading = (store: IRootState) => store.sgw.requests.quarterCost.loading;
export const selectSgwRequestHistory = (store: IRootState) => store.sgw.requests.history;
export const selectSgwRequestPermitHistory = (store: IRootState) => store.sgw.requests.permitHistory;

export const getStandardPhases = createSelector([selectPhases], (phases) =>
  phases
    .filter((phase) => !phase.isRecurringPhaseTemplate && !phase.parentPhaseId)
    .map((phase) => ({ ...phase, recurringPhases: phases.filter((p) => p.parentPhaseId === phase.id) })),
);
export const getStandardAndRecurringPhases = createSelector([selectPhases], (phases) =>
  phases
    .filter((phase) => !phase.isRecurringPhaseTemplate && !phase.parentPhaseId)
    .flatMap((phase) => [phase, ...phases.filter((p) => p.parentPhaseId === phase.id)]),
);

export const getRecurringPhaseTemplates = createSelector([selectPhases], (phases) =>
  phases.filter((phase) => phase.isRecurringPhaseTemplate),
);
export const getRecurringPhaseIds = createSelector([selectPhases], (phases) =>
  phases.filter((phase) => !!phase.parentPhaseId).map((phase) => phase.id),
);

export const getSortedConflictGroups = createSelector([selectConflictGroups], (groups) =>
  [...groups].sort(conflictGroupSorting),
);

export const getSortedConflictGroupsWithPhaseIds = createSelector([selectConflictGroups], (groups) =>
  [...groups].sort(conflictGroupSorting).map((conflictGroup) => ({
    ...conflictGroup,
    phaseIds: conflictGroup.conflicts
      .map((conflict) => conflict.sgwPhaseId)
      .filter((id, index, self) => self.indexOf(id) === index),
  })),
);

export const getConflictsOfConflictGroups = (ids: number[]) =>
  createSelector([selectConflictGroups], (groups) =>
    groups.filter(({ id }) => !ids || ids.includes(id)).flatMap(({ conflicts }) => conflicts),
  );

export const getSgwRequestsList = createAllEntitiesSelector(selectAllIds, selectById);
export const getPagedApiParams = createPagedApiParamsSelector(selectTable);
export const getSgwRequest = (id?: string) =>
  createSelector([selectById], (selectById) => (id ? selectById?.[id] : undefined));

export const getAllPhases = createSelector([selectPhases], (phases) =>
  phases.map((phase, index) => ({
    ...phase,
    phaseName: phase.phaseName || translate('sgw.requests.detail.attachments.phaseName', { index: index + 1 }),
  })),
);

export const getRecurringPhases = createSelector([getAllPhases], (phases) =>
  phases.filter((phase) => !!phase.parentPhaseId),
);

export const getPhaseById = (phaseId: number) =>
  createSelector([selectPhases], (phases) => phases.find(({ id }) => id === phaseId));

export const getPhaseNames = (phases: ISgwPhase[]) =>
  createSelector([getAllPhases], (allPhases) =>
    allPhases
      .filter(({ id }) => phases.map(({ id: phaseId }) => phaseId).includes(id))
      .map(({ phaseName }) => phaseName),
  );

export const addExtraFieldsToSgwRequest = (request: ISgwRequest) => {
  const {
    mainLocation: { street, streetNumberFrom, streetNumberTo, streetNumberUnknown },
  } = request;
  const streetNumber = streetNumberUnknown ? '' : `${streetNumberFrom}-${streetNumberTo}`;
  const fullAddress = street && `${street} ${streetNumber}`;

  return {
    ...request,
    fullAddress,
  };
};

export const getSgwRequestWithAddedFields = createSelector(
  [selectSgwRequest],
  (request: ISgwRequest | null) => request && addExtraFieldsToSgwRequest(request),
);

export const getSgwRequestsListWithAddedFields = createSelector(
  [getSgwRequestsList],
  (requests: ISgwRequest[]): ISgwRequestOverview[] => requests.map(addExtraFieldsToSgwRequest),
);

export const getSgwRequestAssignStatus = createSelector([selectSgwRequest, selectUser], (request, currentUser) => {
  const { userResponsible, userGisResponsible, userMobilityAdvisor, state } = request || {};

  const assignedToMe = !!userResponsible && userResponsible.id === currentUser?.id;
  const assignedGisToMe = !!userGisResponsible && userGisResponsible.id === currentUser?.id;
  const assignedMobilityAdvisorToMe = !!userMobilityAdvisor && userMobilityAdvisor.id === currentUser?.id;
  const allowEditRequest = assignedToMe || assignedGisToMe || assignedMobilityAdvisorToMe;

  return {
    assignedUser: userResponsible,
    assignedToMe,
    assignedGisToMe,
    assignedMobilityAdvisorToMe,
    allowEditRequest,
    allowEditMotivation: allowEditRequest && state === SgwRequestState.reviewing,
  };
});

export const getSgwRequestNotes = () => createSelector([selectSgwRequest], (request) => request?.backofficeNotes || '');

export const getSgwRequestAssignedUsername = createSelector(
  [getSgwRequestAssignStatus],
  ({ assignedUser, assignedToMe }) => {
    if (assignedToMe) return translate('Requests.Header.assign.me');
    if (assignedUser?.fullName) return assignedUser.fullName;
    return translate('Requests.Header.assign.nobody');
  },
);

export const getRequestPartnersId = createSelector(
  [selectSgwRequest],
  (request): IByPartnerType<number | undefined> => ({
    [SgwPartnerType.requester]: request?.requestorId,
    [SgwPartnerType.client]: request?.principalId,
    [SgwPartnerType.signalisation]: request?.signallingResponsibleId,
    [SgwPartnerType.contractor]: request?.mainContractorId,
    [SgwPartnerType.fee]: request?.feeResponsibleId,
  }),
);

export const getAttachmentsByPhaseId = (id: number) =>
  createSelector([selectAttachments], (attachments): ISgwRequestAttachment[] =>
    attachments.filter(({ sgwPhases }) => sgwPhases.map(({ id }) => id).includes(id)),
  );

export const getPhaseLocations = createSelector([selectPhases], (phases) =>
  phases.flatMap(({ sgwGeoDrawings }) => sgwGeoDrawings),
);

export const getPhasesBounds = createSelector([getPhaseLocations], (sgwGeoDrawings) =>
  getBoundsOfGeometries(sgwGeoDrawings.map(({ geometry }) => geometry)),
);

export const getPhasesCenter = createSelector([getPhaseLocations], (sgwGeoDrawings) =>
  getCenterOfGeometries(sgwGeoDrawings.map(({ geometry }) => geometry)),
);

export const getPhaseMenuItems = createSelector(
  [getStandardPhases, getRecurringPhaseTemplates],
  (standardPhases, recurringPhaseTemplates) => [
    ...standardPhases.flatMap(({ id, recurringPhases }, i) => [
      {
        label: translate('sgw.requests.detail.map.phaseName', { index: i + 1 }),
        value: id,
      },
      ...recurringPhases.map(({ id }, i) => ({
        label: `${translate('sgw.requests.detail.phases.recurringPhase')} ${i + 1}`,
        value: id,
      })),
    ]),
    ...recurringPhaseTemplates.map(({ id }, i) => ({
      label: `${translate('sgw.requests.detail.phases.recurringPhaseTemplate')} ${i + 1}`,
      value: id,
    })),
  ],
);

export const getCopyablePhases = createSelector([selectPhases], (phases) =>
  phases.filter(({ sgwGeoDrawings }) => sgwGeoDrawings.length),
);

export const getCopyablePhaseMenuItems = (excludedId?: number) =>
  createSelector([getCopyablePhases, getPhaseMenuItems], (phases, menuItems) =>
    menuItems.filter(({ value }) => phases.some(({ id }) => id === value && value !== excludedId)),
  );

export const getGeoDrawingsByPhaseId = (phaseId: number) =>
  createSelector([getPhaseById(phaseId)], (phase): ISgwGeoDrawing[] | undefined =>
    phase?.sgwGeoDrawings.map(({ id, ...phaseValues }) => ({ ...phaseValues })),
  );

export const getSgwRequestPermitConditionIds = createSelector(
  [selectSgwRequestPermitConditions],
  (permitConditions) => permitConditions?.map(({ id }) => id).sort(sortById) || [],
);

export const getSelectedPermitConditionsByCategory = (_category: SgwPermitConditionCategory) =>
  createSelector(
    [selectSgwRequestPermitConditions],
    (permitConditions) =>
      permitConditions?.filter(({ active, category }) => active && category === _category).sort(sortById) || [],
  );

export const getSelectedConflictGroup = (id?: number) =>
  createSelector([selectConflictGroups], (groups) => groups.find((group) => group.id === id));

export const getConflictGroupMessagesById = (conflictGroupId: number) =>
  createSelector(
    [selectConflictGroupMessagesById],
    (selectById) => selectById?.[conflictGroupId] || initialRequestsState.requestMessagesList,
  );

export const getConflictGroupOptions = () =>
  createSelector([selectConflictGroups], (conflictGroups) =>
    getValuesOfEnum(ConflictCategory)
      .filter(
        (category) => !conflictGroups.find((conflictGroup) => conflictGroup.conflictCategory === category) && category,
      )
      .map((category) => ({
        label: translateIgnoreTS(`sgw.requests.detail.conflicts.conflictCategoryDisplayName.${category}`),
        value: category,
      })),
  );

export const getQuartersInRequestOptions = () =>
  createSelector([selectSgwRequest], (sgwRequest) =>
    allQuartersBetweenDates(sgwRequest?.dateFrom, sgwRequest?.dateUntil).map((q) => ({
      label: `${q.year} ${translate('sgw.requests.detail.attachments.table.quarter')} ${q.index}`,
      value: stringQuarter(q),
    })),
  );
export const getStandardPhaseOptions = () =>
  createSelector([getStandardPhases], (phases) =>
    phases.map((phase, index) => ({
      label: phase.phaseName || `${translate('sgw.requests.detail.phases.title')} ${index + 1}`,
      value: phase.id,
    })),
  );

export const getTotalRequesCost = () =>
  createSelector([selectSgwQuarterCosts], (quarterCosts) =>
    quarterCosts.reduce((prev, value): any => prev + value.quarterCost, 0),
  );

export const getTotalRequesCorrection = () =>
  createSelector([selectSgwQuarterCosts], (quarterCosts) =>
    quarterCosts.reduce(
      (prev, value): any => prev + (!!value?.quarterCorectionCost ? value.quarterCorectionCost : 0),
      0,
    ),
  );

export const getPhasesWithTotalAndMaxSurfaceArea = () =>
  createSelector([getStandardAndRecurringPhases], (phases) =>
    phases.map((phase) => {
      const totalSurfaceArea = phase.sgwGeoDrawings
        .filter(({ geoDrawingType }) => geoDrawingType === GeoDrawingType.constructionzone)
        .reduce((total, value) => total + (value?.surfaceArea || 0), 0);
      return {
        ...phase,
        totalSurfaceArea: totalSurfaceArea,
        maxSurfaceArea: totalSurfaceArea + (phase.exemptedSurfaceArea || 0),
      };
    }),
  );

export const getLowestDateFrom = (phase?: Partial<ISgwPhase>) =>
  createSelector([getStandardPhases], (phases) =>
    phase
      ? lowestDate([phase, ...phases.filter((p) => p.id !== phase?.id)], 'dateFrom')
      : lowestDate(phases, 'dateFrom'),
  );

export const getHighestDateUntil = (phase?: Partial<ISgwPhase>) =>
  createSelector([getStandardPhases], (phases) =>
    phase
      ? highestDate([phase, ...phases.filter((p) => p.id !== phase?.id)], 'dateUntil')
      : highestDate(phases, 'dateUntil'),
  );

export const getCostQuarterPhases = (costPhases: IPhaseQuarterCost[]) =>
  createSelector([getStandardPhases, getRecurringPhases], (phases, recurringPhases) =>
    costPhases
      .filter((costPhase) => phases.some((p) => p.id === costPhase.phaseId))
      .map((costphase) => ({
        ...costphase,
        recurringPhases: costPhases.filter((p) =>
          recurringPhases.filter((r) => r.parentPhaseId === costphase.phaseId).some((r) => r.id === p.phaseId),
        ),
      })),
  );

export const getConflictsLoading = createSelector(
  [selectSgwRequest],
  (request) => request?.conflictDetectionState && ['running', 'queued'].includes(request?.conflictDetectionState),
);

export const getConflictDetectionError = createSelector(
  [selectSgwRequest],
  (request) => request?.conflictDetectionState === 'error',
);

export const getConflictDetectionLastRun = createSelector([selectSgwRequest], (request) =>
  request?.conflictDetectionLastRun ? moment(request?.conflictDetectionLastRun).format(DateFormat.dateTime) : undefined,
);
