import { Tooltip } from '@material-ui/core';
import * as React from 'react';
import { enumToArray } from '../../common';
import { WorkOrderItemTypes } from '../planning.constants';
import { translate } from '../../common/translations/translate';
import { WorkOrderItemTypeIcon } from '../../components/atoms/WorkorderItemTypeIcon/WorkOrderitemTypeIcon.component';
import { CheckboxWithLabel } from '../../common/components/form-fields/checkboxWithLabel.component';
import { FC } from 'react';

interface IProps {
  types: WorkOrderItemTypes[];
  typesChange: (types: WorkOrderItemTypes[]) => void;
}

export const WorkOrderItemTypeFilter: FC<IProps> = ({ types, typesChange }: IProps) => (
  <>
    {enumToArray(WorkOrderItemTypes).map((type: WorkOrderItemTypes) => (
      <Tooltip
        title={translate(`planning.workOrderItem.filter.${type}`)}
        PopperProps={{
          modifiers: { offset: { enabled: true, offset: '0, -80' } },
        }}
      >
        <div key={type} data-testid={`WorkOrderItemTypeFilter-${type}`}>
          <CheckboxWithLabel
            data-testid={`WorkOrderItemTypeCheckbox-${type}`}
            label={<WorkOrderItemTypeIcon type={type} />}
            checked={types.includes(type)}
            onChange={(checked) => {
              typesChange(checked ? [...types, type] : types.filter((other) => other !== type));
            }}
          />
        </div>
      </Tooltip>
    ))}
  </>
);
