import { Grid, Paper } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Container, PanelAction } from '../../../../../../common';
import { Header } from '../../../../../../common/components/layout/Header.component';
import { LabelValue } from '../../../../../../common/components/layout/LabelValue.component';
import { PanelContent } from '../../../../../../common/components/panel/panel-content.component';
import { appUrls } from '../../../../../../common/config/url.constants';
import { translate, translateIgnoreTS } from '../../../../../../common/translations/translate';
import { SgwPermitConditionsActions } from '../../../../../../store/actions';
import { IRouterWithId, ISgwPermitConditionsAclActions } from '../../../../../../types';
import { Auth } from '../../../../../atoms';
import { useFormStyles } from '../../../../../../style/form.styles';
import { getSgwPermitCondition } from '../../../../../../store/selectors/sgwPermitConditions.selectors';
import { Loader } from '../../../../../../common/components/layout/loader.component';

export const DetailPermitConditionPage: FC = () => {
  const C = useFormStyles();
  const dispatch = useDispatch();
  const { id } = useParams<IRouterWithId>();
  const permitCondition = useSelector(getSgwPermitCondition(id));
  const history = useHistory();

  useEffect(() => {
    id && dispatch(SgwPermitConditionsActions.fetch(id));
  }, [dispatch, id]);

  const onClick = () => history.push(appUrls.sgw.manage.permitConditions.edit(id));

  if (!permitCondition) {
    return <Loader />;
  }

  const { title, referenceCode, category, active, description } = permitCondition;

  return (
    <Auth acl={ISgwPermitConditionsAclActions.viewSgwPermitConditionDetail} showUnauthorizedPage>
      <Header backButtonUrl={appUrls.sgw.manage.permitConditions.base}>
        {translate('sgw.permitConditions.permitConditionName', { permitCondition: `${referenceCode} ${title}` })}
      </Header>
      <Container className={C.container} data-testid="permitConditionInfo">
        <Paper square>
          <PanelContent title={translate('sgw.permitConditions.details')}>
            <Auth acl={ISgwPermitConditionsAclActions.editSgwPermitConditions}>
              <PanelAction icon={<Edit />} onClick={onClick} data-testid="editButton" />
            </Auth>
            <Grid container spacing={2} className={C.grid}>
              <LabelValue label={translate('sgw.permitConditions.columns.category')}>
                {translateIgnoreTS(`sgw.permitConditions.categories.${category}`)}
              </LabelValue>
              <LabelValue label={translate('sgw.permitConditions.columns.referenceCode')}>{referenceCode}</LabelValue>
              <LabelValue label={translate('sgw.permitConditions.columns.title')}>{title}</LabelValue>
              <LabelValue label={translate('sgw.permitConditions.columns.description')}>{description}</LabelValue>
              <LabelValue label={translate('sgw.permitConditions.active')}>
                {translate(active ? 'yes' : 'no')}
              </LabelValue>
            </Grid>
          </PanelContent>
        </Paper>
      </Container>
    </Auth>
  );
};
