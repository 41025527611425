import { Grid } from '@material-ui/core';
import { LabelValue } from '../../../common/components/layout/LabelValue.component';
import { translate, translateIgnoreTS } from '../../../common/translations/translate';
import { convertDateFormat } from '../../../common/utils/date.util';
import { getSgwPartner } from '../../../store/selectors/sgwPartners.selectors';
import { Capacity, DateFormat, ISgwRequestInfo, WorkType } from '../../../types';
import classNames from 'classnames';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useRequestDetailStyles } from './requestDetailInfo.styles';
import { useMetadata } from '../../../hooks';
import { Visible } from '../../../common/components/layout/Visible.component';
import { SgwPriorityPicker } from '../../molecules/SgwPriorityPicker/SgwPriorityPicker.component';
import { appUrls } from '../../../common/config/url.constants';
import { ViewUserField } from '../../atoms';

export const RequestDetailInfoContent: FC<ISgwRequestInfo> = ({
  id,
  bonuNumber,
  referenceId,
  dateFrom,
  dateUntil,
  gipodId,
  mainLocation,
  workDescription,
  crane,
  sgwDistrictIds,
  sgwProject,
  prio,
  cluster,
  workType,
  requestorId,
  userRequested,
  utilityCompanyName,
  utilityCompanyNameResponsible,
  utilityCompanyPhoneResponsible,
  utilityCompanyEmailResponsible,
}) => {
  const C = useRequestDetailStyles();
  const districts = useMetadata().sgw.districts.filter(({ id }) => sgwDistrictIds?.includes(id));
  const partner = useSelector(getSgwPartner(requestorId));

  return (
    <Grid container className={C.gridContainer}>
      <Grid container xs={6} spacing={2} className={C.resetSpacing}>
        <LabelValue label={translate('sgw.requests.detail.info.identificationNumber')}>{id}</LabelValue>
        <LabelValue label={translate('sgw.requests.detail.info.period')}>
          {convertDateFormat(dateFrom, DateFormat.reduxStoreDateString, DateFormat.date)} -
          {convertDateFormat(dateUntil, DateFormat.reduxStoreDateString, DateFormat.date)}
        </LabelValue>
        <LabelValue visible={!!cluster?.displayName} label={translate('sgw.requests.detail.info.cluster')}>
          {cluster?.displayName}
        </LabelValue>
        <LabelValue label={translate('sgw.requests.detail.info.district')} visible={!!districts}>
          {districts.map(({ name }) => name).join(', ')}
        </LabelValue>
        <LabelValue label={translate('sgw.requests.detail.info.referenceNumber')} visible={!!referenceId}>
          {referenceId}
        </LabelValue>
        <SgwPriorityPicker prio={prio} readOnly />
        <LabelValue label={translate('sgw.requests.detail.info.projectName')} visible={!!sgwProject}>
          {sgwProject?.name}
        </LabelValue>
      </Grid>
      <Grid container xs={6} spacing={2} className={classNames(C.resetSpacing, C.fitContent)}>
        <LabelValue label={translate('sgw.requests.detail.info.requestedBy')} visible={!!partner}>
          <Visible
            visible={partner?.capacity === Capacity.Business}
            defaultComponent={
              <>
                {partner?.firstName} {partner?.lastName}
              </>
            }
          >
            {partner?.companyName}
          </Visible>
        </LabelValue>
        <LabelValue label={translate('sgw.requests.detail.info.createdBy')} visible={!!userRequested?.fullName}>
          <ViewUserField name={userRequested?.fullName} url={appUrls.users.detail(`${userRequested?.id}`, true)} />
        </LabelValue>

        <LabelValue label={translate('sgw.requests.detail.info.address')} visible={!!mainLocation}>
          {`${mainLocation.street} ${
            mainLocation.streetNumberUnknown ? '' : `${mainLocation.streetNumberFrom} - ${mainLocation.streetNumberTo}`
          }`}
        </LabelValue>
        <LabelValue label={translate('sgw.requests.detail.info.typeOfWorkZone')}>
          {translateIgnoreTS(`sgw.requests.workTypes.${workType}`)}
        </LabelValue>
        <LabelValue visible={!!bonuNumber} label={translate('sgw.requests.detail.info.bonuNumber')}>
          {bonuNumber}
        </LabelValue>
        <LabelValue visible={!!gipodId} label={translate('sgw.requests.detail.info.gipodId')}>
          {gipodId}
        </LabelValue>
        <Visible visible={!!gipodId || workType === WorkType.GroundWorksToPublicDomain}>
          <p className={C.infoText}>{translate('sgw.requests.detail.info.gipodSyncInfo')}</p>
        </Visible>
        <LabelValue visible={!!utilityCompanyName} label={translate('sgw.requests.detail.info.utilityCompanyName')}>
          {utilityCompanyName}
        </LabelValue>
        <LabelValue
          visible={!!utilityCompanyNameResponsible}
          label={translate('sgw.requests.detail.info.utilityCompanyNameResponsible')}
        >
          {utilityCompanyNameResponsible}
        </LabelValue>
        <LabelValue
          visible={!!utilityCompanyPhoneResponsible}
          label={translate('sgw.requests.detail.info.utilityCompanyPhoneResponsible')}
        >
          {utilityCompanyPhoneResponsible}
        </LabelValue>
        <LabelValue
          visible={!!utilityCompanyEmailResponsible}
          label={translate('sgw.requests.detail.info.utilityCompanyEmailResponsible')}
        >
          {utilityCompanyEmailResponsible}
        </LabelValue>
      </Grid>
      <LabelValue label={translate('sgw.requests.detail.info.useOfCrane')} className={C.bottomSpacing}>
        {translate(crane.isUsed ? 'yes' : 'no')}
      </LabelValue>
      <Visible visible={!!crane.isUsed}>
        <LabelValue label={translate('sgw.requests.detail.info.moreThan25Tons')} className={C.bottomSpacing}>
          {translate(crane.moreThan25Tons ? 'yes' : 'no')}
        </LabelValue>
      </Visible>
      <LabelValue
        label={translate('sgw.requests.detail.info.craneMaxHeight')}
        visible={!!crane.maxHeight}
        className={C.bottomSpacing}
      >
        {translate('sgw.requests.detail.info.inMeters', { height: crane.maxHeight })}
      </LabelValue>
      <LabelValue
        label={translate('sgw.requests.detail.info.craneType')}
        visible={!!crane.type}
        className={C.bottomSpacing}
      >
        {crane.type}
      </LabelValue>
      <LabelValue label={translate('sgw.requests.detail.info.requestorDescription')} className={C.bottomSpacing}>
        {workDescription}
      </LabelValue>
    </Grid>
  );
};
