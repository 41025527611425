import { Card, Grid, Tooltip } from '@material-ui/core';
import {
  ArrowDownward,
  ArrowUpward,
  Build,
  Edit,
  LowPriority,
  Notifications,
  ReportProblemOutlined,
} from '@material-ui/icons';
import * as React from 'react';
import { FC, useState } from 'react';
import { useHistory } from 'react-router';
import { COLORS } from '../../common';
import { Button } from '../../common/components/buttons/asign-button-extensions';
import TourIcon from '../../common/components/tour-icon/tour-icon';
import { WorkOrderItemTypes } from '../planning.constants';
import TeamStatusItems from './team-status-items.component';
import { ITeam, IWorkOrder, IWorkOrderItem, IWorkOrderItemAclActions } from '../../types';
import { useAuthorization } from '../../hooks';
import { translate } from '../../common/translations/translate';
import { appUrls } from '../../common/config/url.constants';

interface IProps {
  workOrdersLoading?: boolean;
  workOrder?: IWorkOrder;
  team: ITeam;
  key: number;
  teamWoiClick: (woi: IWorkOrderItem) => void;
  updateWorkOrder: (order: IWorkOrder) => void;
  planningDisabled?: boolean;
}

interface ITotals {
  totalWoi: number;
  needMaintenance: number;
  processedWoi: number;
  dropOff: number;
  pickUp: number;
  update: number;
  notification: number;
  hasDropOffZoneSigns: boolean;
  hasPickUpZoneSigns: boolean;
}

export const TeamStatus: FC<IProps> = ({
  workOrdersLoading,
  workOrder,
  team,
  teamWoiClick,
  updateWorkOrder,
  planningDisabled,
}: IProps) => {
  const [expand, setExpand] = useState<boolean>(false);
  const history = useHistory();
  const { isAuthorized: canReorderWorkOrderItems } = useAuthorization([IWorkOrderItemAclActions.reorderWorkOrderItems]);

  const workOrderItems = workOrder ? workOrder.workOrderItems : [];

  const toggle = () => {
    setExpand(!expand);
  };

  const hasZoneSigns = ({ hasZoneSigns }: IWorkOrderItem) => hasZoneSigns;

  const calculateTeamTotals = (items: IWorkOrderItem[]): ITotals => {
    const pickUp = items
      .filter((woi) => {
        return woi.type === WorkOrderItemTypes.pick_up && woi.parkingBanIntake;
      })
      .reduce((count: number, woi: IWorkOrderItem) => count + (woi.numberOfSigns || 0), 0);

    const dropOff = items
      .filter((woi) => {
        return woi.type === WorkOrderItemTypes.drop_off && woi.parkingBanIntake;
      })
      .reduce(
        (count: number, woi: IWorkOrderItem) =>
          count + (woi.parkingBanIntake!.numberOfSigns || 0) + (woi.parkingBanIntake!.numberOfZoneSigns || 0),
        0,
      );

    const update = items
      .filter((woi) => {
        return woi.type === WorkOrderItemTypes.update && woi.parkingBanIntake;
      })
      .reduce((count: number, woi: IWorkOrderItem) => count + (woi.numberOfSigns || 0), 0);

    const needMaintenance = items
      .filter((woi) => {
        return woi.type === WorkOrderItemTypes.pick_up && woi.parkingBanIntake;
      })
      .reduce(
        (count: number, woi: IWorkOrderItem) => count + (woi.parkingBanIntake!.numberOfSignsNeedMaintenance || 0),
        0,
      );

    const notification = items
      .filter((woi) => {
        return woi.type === WorkOrderItemTypes.notification;
      })
      .reduce((count: number, woi: IWorkOrderItem) => count + (woi.numberOfSigns || 0), 0);

    const totalWoi = items.length;

    const processedWoi = items.filter((woi) => {
      return woi.state === 'done';
    }).length;

    const hasPickUpZoneSigns = items.filter((item) => item.type === WorkOrderItemTypes.pick_up).some(hasZoneSigns);
    const hasDropOffZoneSigns = items.filter((item) => item.type === WorkOrderItemTypes.drop_off).some(hasZoneSigns);

    return {
      dropOff,
      hasPickUpZoneSigns,
      hasDropOffZoneSigns,
      needMaintenance,
      notification,
      pickUp,
      processedWoi,
      totalWoi,
      update,
    };
  };

  const createGotoReorderingClick = (teamId?: number) => () => {
    if (teamId) history.push(appUrls.planning.reordering.detail(`${teamId}`));
  };

  const totals = calculateTeamTotals(workOrderItems || []);
  const allChecked = workOrderItems!.every((w) => !!w.checked);

  return (
    <Card style={{ marginBottom: 10, color: COLORS.GREY, background: COLORS.TEAM_CARD_BG }}>
      <Grid
        data-testid="TeamStatus"
        container
        justify="space-between"
        alignItems="center"
        style={{ padding: 8, cursor: 'pointer' }}
        onClick={toggle}
        wrap="nowrap"
      >
        <Grid container justify="center" alignItems="center" wrap="nowrap">
          <Grid item style={{ flex: 1, fontSize: 16 }}>
            <TourIcon
              checked={allChecked}
              color={team.color}
              tooltip={allChecked ? translate('allChecked') : translate('notAllChecked')}
              style={{ width: 20 }}
            />
          </Grid>
          <Grid item style={{ flex: 10, paddingLeft: 6, paddingBottom: 5, whiteSpace: 'nowrap' }}>
            {team.name} {totals.totalWoi > 0 && `(${totals.processedWoi} / ${totals.totalWoi})`}
            <Button.White
              visible={
                !!(
                  expand &&
                  workOrder?.id &&
                  workOrder?.acl.canReorder &&
                  workOrder?.workOrderItems?.length &&
                  workOrder.workOrderItems.length > 1 &&
                  canReorderWorkOrderItems
                )
              }
              style={{ textTransform: 'none', minWidth: 46, marginLeft: 10 }}
              id={`order_${team.id}`}
              onClick={createGotoReorderingClick(workOrder?.id)}
              data-testid="reorderButton"
            >
              <LowPriority fontSize={'small'} />
            </Button.White>
          </Grid>
        </Grid>
        {[
          {
            count: totals.pickUp,
            icon: <ArrowUpward fontSize="small" color={totals.hasPickUpZoneSigns ? 'error' : 'inherit'} />,
            key: 'pick_up',
            label: totals.hasPickUpZoneSigns ? translate('signsToPickUpIncludesZoneSigns') : translate('signsToPickUp'),
            color: totals.hasPickUpZoneSigns ? COLORS.DANGER : COLORS.GREY,
          },
          {
            count: totals.dropOff,
            icon: <ArrowDownward fontSize="small" color={totals.hasDropOffZoneSigns ? 'error' : 'inherit'} />,
            key: 'drop_off',
            label: totals.hasDropOffZoneSigns
              ? translate('signsToDropOffIncludesZoneSigns')
              : translate('signsToDropOff'),
            color: totals.hasDropOffZoneSigns ? COLORS.DANGER : COLORS.GREY,
          },
          {
            count: totals.update,
            icon: <Edit fontSize="small" />,
            key: 'SignsToUpdate',
            label: translate('signsToUpdate'),
          },
          {
            count: totals.needMaintenance,
            icon: <Build fontSize="small" />,
            key: 'maintenance',
            label: translate('maintenance'),
          },
          {
            count: totals.notification,
            icon: <Notifications fontSize="small" />,
            key: 'notification',
            label: translate('Notifications.filter'),
          },
          {
            count: workOrder?.team.warnings || 0,
            icon: <ReportProblemOutlined fontSize="small" color="error" />,
            key: 'warning',
            label: translate('planning.workOrderItem.incomplete'),
          },
        ].map((item) => {
          if (item.count === 0 && ['maintenance', 'notification', 'warning'].includes(item.key)) {
            return null;
          }
          return (
            <Grid key={item.key} item style={{ flex: 1, padding: 5 }}>
              <Tooltip title={item.label}>
                <Grid container spacing={0} justify="center" alignItems="center" wrap="nowrap">
                  <Grid item>{item.icon}</Grid>
                  <Grid item style={{ paddingBottom: 5, paddingLeft: 5, color: item.color }}>
                    <span id={item.key}>{item.count}</span>
                  </Grid>
                </Grid>
              </Tooltip>
            </Grid>
          );
        })}
      </Grid>
      {expand && !!workOrder?.workOrderItems?.length && (
        <TeamStatusItems
          workOrder={workOrder!}
          teamWoiClick={teamWoiClick}
          updateWorkOrder={updateWorkOrder}
          workOrdersLoading={workOrdersLoading}
          planningDisabled={planningDisabled}
        />
      )}
    </Card>
  );
};
