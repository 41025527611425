import L from 'leaflet';
import { COLORS } from '../..';

const getMarkerIcon = (color: string) =>
  new L.DivIcon({
    className: 'marker',
    iconAnchor: [16, 43],
    iconSize: [32, 43],
    html: `<svg width="32px" height="43px" viewBox="0 0 32 43"><path d="M15,42 C25,28.9938602 30,19.8234704 30,14.4888303 C30,6.4868703 23.2842712,0 15,0 C6.71572875,0 0,6.4868703 0,14.4888303 C0,19.8234704 5,28.9938602 15,42 Z" stroke="#979797" fill="#FFFFFF"></path><circle stroke="${color}" stroke-width="4" cx="15" cy="15" r="9" fill="transparent"></circle></svg>`,
  });

const WarningMarkerIcon = () =>
  new L.DivIcon({
    className: 'marker',
    iconAnchor: [30, 30],
    iconSize: [32, 32],
    popupAnchor: [1, 40],
    html: `<svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M48.332 41.4375L32.082 13.3125C31.8399 12.8945 31.4219 12.6875 31 12.6875C30.5781 12.6875 30.1563 12.8945 29.918 13.3125L13.668 41.4375C13.1875 42.2734 13.7891 43.3125 14.75 43.3125H47.25C48.2109 43.3125 48.8125 42.2734 48.332 41.4375ZM17.7266 40.3477L31 17.3711L44.2734 40.3477H17.7266Z" fill="#F5222D"/>
<path d="M17.728 40.3478H44.2749L31.0015 17.3712L17.728 40.3478ZM32.8765 36.1251C32.8664 36.6158 32.6645 37.083 32.314 37.4264C31.9634 37.7699 31.4922 37.9622 31.0015 37.9622C30.5107 37.9622 30.0395 37.7699 29.689 37.4264C29.3384 37.083 29.1365 36.6158 29.1265 36.1251C29.1365 35.6345 29.3384 35.1673 29.689 34.8238C30.0395 34.4804 30.5107 34.288 31.0015 34.288C31.4922 34.288 31.9634 34.4804 32.314 34.8238C32.6645 35.1673 32.8664 35.6345 32.8765 36.1251ZM32.2515 24.2501V31.4376C32.2515 31.6095 32.1108 31.7501 31.939 31.7501H30.064C29.8921 31.7501 29.7515 31.6095 29.7515 31.4376V24.2501C29.7515 24.0782 29.8921 23.9376 30.064 23.9376H31.939C32.1108 23.9376 32.2515 24.0782 32.2515 24.2501Z" fill="white"/>
<path d="M29.125 36.125C29.125 36.6223 29.3225 37.0992 29.6742 37.4508C30.0258 37.8025 30.5027 38 31 38C31.4973 38 31.9742 37.8025 32.3258 37.4508C32.6775 37.0992 32.875 36.6223 32.875 36.125C32.875 35.6277 32.6775 35.1508 32.3258 34.7992C31.9742 34.4475 31.4973 34.25 31 34.25C30.5027 34.25 30.0258 34.4475 29.6742 34.7992C29.3225 35.1508 29.125 35.6277 29.125 36.125ZM29.75 24.25V31.4375C29.75 31.6094 29.8906 31.75 30.0625 31.75H31.9375C32.1094 31.75 32.25 31.6094 32.25 31.4375V24.25C32.25 24.0781 32.1094 23.9375 31.9375 23.9375H30.0625C29.8906 23.9375 29.75 24.0781 29.75 24.25Z" fill="#F5222D"/>
</svg>
`,
  });

const WarningMarkerActiveIcon = () =>
  new L.DivIcon({
    className: 'marker',
    iconAnchor: [30, 30],
    iconSize: [32, 32],
    popupAnchor: [1, 40],
    html: `<svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="30.5" cy="30.5" r="30.5" fill="#F5222D" fill-opacity="0.3"/>
<path d="M48.332 41.4375L32.082 13.3125C31.8399 12.8945 31.4219 12.6875 31 12.6875C30.5781 12.6875 30.1563 12.8945 29.918 13.3125L13.668 41.4375C13.1875 42.2734 13.7891 43.3125 14.75 43.3125H47.25C48.2109 43.3125 48.8125 42.2734 48.332 41.4375ZM17.7266 40.3477L31 17.3711L44.2734 40.3477H17.7266Z" fill="#F5222D"/>
<path d="M17.728 40.3478H44.2749L31.0015 17.3712L17.728 40.3478ZM32.8765 36.1251C32.8664 36.6158 32.6645 37.083 32.314 37.4264C31.9634 37.7699 31.4922 37.9622 31.0015 37.9622C30.5107 37.9622 30.0395 37.7699 29.689 37.4264C29.3384 37.083 29.1365 36.6158 29.1265 36.1251C29.1365 35.6345 29.3384 35.1673 29.689 34.8238C30.0395 34.4804 30.5107 34.288 31.0015 34.288C31.4922 34.288 31.9634 34.4804 32.314 34.8238C32.6645 35.1673 32.8664 35.6345 32.8765 36.1251ZM32.2515 24.2501V31.4376C32.2515 31.6095 32.1108 31.7501 31.939 31.7501H30.064C29.8921 31.7501 29.7515 31.6095 29.7515 31.4376V24.2501C29.7515 24.0782 29.8921 23.9376 30.064 23.9376H31.939C32.1108 23.9376 32.2515 24.0782 32.2515 24.2501Z" fill="white"/>
<path d="M29.125 36.125C29.125 36.6223 29.3225 37.0992 29.6742 37.4508C30.0258 37.8025 30.5027 38 31 38C31.4973 38 31.9742 37.8025 32.3258 37.4508C32.6775 37.0992 32.875 36.6223 32.875 36.125C32.875 35.6277 32.6775 35.1508 32.3258 34.7992C31.9742 34.4475 31.4973 34.25 31 34.25C30.5027 34.25 30.0258 34.4475 29.6742 34.7992C29.3225 35.1508 29.125 35.6277 29.125 36.125ZM29.75 24.25V31.4375C29.75 31.6094 29.8906 31.75 30.0625 31.75H31.9375C32.1094 31.75 32.25 31.6094 32.25 31.4375V24.25C32.25 24.0781 32.1094 23.9375 31.9375 23.9375H30.0625C29.8906 23.9375 29.75 24.0781 29.75 24.25Z" fill="#F5222D"/>
</svg>
`,
  });

export const Markers = {
  Blue: getMarkerIcon(COLORS.PRIMARY),
  Red: getMarkerIcon(COLORS.DANGER),
  WithColor: (color: string) => getMarkerIcon(color),
  Warning: WarningMarkerIcon(),
  WarningActive: WarningMarkerActiveIcon(),
};

export const MaterialMarkerIcon = (
  icon: string,
  iconColor: string = COLORS.WHITE,
  backgroundColor: string = COLORS.PRIMARY,
  fillColor: string = COLORS.WHITE,
) => {
  if (icon.includes('.svg')) {
    return new L.DivIcon({
      className: 'marker',
      iconAnchor: [16, 43],
      iconSize: [32, 43],
      html: `<span><img src=${icon} style="position: absolute; color: ${iconColor}; font-size: 18px; z-index: 201; top: 7px; left: 7px;" alt='icon'/><svg width="32px" height="43px" viewBox="0 0 32 43"><path d="M15,42 C25,28.9938602 30,19.8234704 30,14.4888303 C30,6.4868703 23.2842712,0 15,0 C6.71572875,0 0,6.4868703 0,14.4888303 C0,19.8234704 5,28.9938602 15,42 Z" stroke="#979797" fill="${fillColor}"></path><circle cx="15" cy="15" r="11" fill="${backgroundColor}"></circle></svg></span>`,
    });
  }

  return new L.DivIcon({
    className: 'marker',
    iconAnchor: [16, 43],
    iconSize: [32, 43],
    html: `<span><i style="position: absolute; color: ${iconColor}; font-size: 18px; z-index: 201; top: 6px; left: 6px;" class="material-icons">${icon}</i><svg width="32px" height="43px" viewBox="0 0 32 43"><path d="M15,42 C25,28.9938602 30,19.8234704 30,14.4888303 C30,6.4868703 23.2842712,0 15,0 C6.71572875,0 0,6.4868703 0,14.4888303 C0,19.8234704 5,28.9938602 15,42 Z" stroke="#979797" fill="${fillColor}"></path><circle cx="15" cy="15" r="11" fill="${backgroundColor}"></circle></svg></span>`,
  });
};

export const ReorderingMarkerIcon = (
  text: string,
  textColor: string = COLORS.WHITE,
  backgroundColor: string = COLORS.PRIMARY,
  strokeColor: string = COLORS.PRIMARY,
  strokeWidth = 2,
) =>
  new L.DivIcon({
    className: 'marker',
    iconAnchor: [16, 43],
    iconSize: [32, 43],
    html: `<span><div style="position: absolute; width: 32px; color: ${textColor}; font-size: 14px; z-index: 201; top: 10px; left: 0px; font-weight: bold; text-align: center;">${text}</div><svg width="32px" height="46px" viewBox="${
      0 - strokeWidth
    } ${0 - strokeWidth} ${32 + strokeWidth} ${
      43 + strokeWidth
    }"><path d="M15,42 C25,28.9938602 30,19.8234704 30,14.4888303 C30,6.4868703 23.2842712,0 15,0 C6.71572875,0 0,6.4868703 0,14.4888303 C0,19.8234704 5,28.9938602 15,42 Z" stroke="${strokeColor}" stroke-width="${strokeWidth}" stroke-" fill="${backgroundColor}"></path></svg></span>`,
  });
