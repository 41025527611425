import axios from 'axios';
import { CONFIG, IApiPromise, IPagedApiParams, ISorting } from '../../common';
import {
  IRequestMessageBE,
  IRequestMessagesList,
  ISgwRequest,
  ISgwRequestBE,
  ISgwRequestCounters,
  ISgwRequestCountersFilter,
  ISgwRequestPatchInternalNotes,
  ISgwPhase,
  ISgwRequestPostTransition,
  ISgwRequestAttachment,
  IConflictGroup,
  ISgwRequestPermit,
  ISgwRequestCopyAttachment,
  ISgwRequestsFilter,
} from '../../types';
import { ISgwQuarterCost } from '../../types/SgwQuarterCost.types';
import { ISgwHistory } from '../../types/sgwRequestHistory.types';

const BASE_SGW_REQUEST_URL = '/v1/sgw/requests';
const ADVISING_PARTIES_SGW_REQUEST_URL = '/v1/sgw/advising_parties/requests';

const conflicts = {
  sync: (id: number): IApiPromise<void> =>
    axios.get(`${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${id}/check_conflicts`),
  fetchList: (id: string): IApiPromise<IConflictGroup[]> =>
    axios.get(`${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${id}/conflict_groups`),
  sendEmailAP: (id: string, conflictId: number): IApiPromise<void> =>
    axios.post(`${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${id}/conflict_groups/${conflictId}/request_advice`),
  save: (requestId: number, { id, ...conflictGroup }: Partial<IConflictGroup>): IApiPromise<IConflictGroup> =>
    id
      ? axios.patch(`${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${requestId}/conflict_groups/${id}`, conflictGroup)
      : axios.post(`${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${requestId}/conflict_groups`, conflictGroup),
  fetchMessages: (requestId: number, conflictGroupId: number): IApiPromise<IRequestMessagesList> =>
    axios.get(
      `${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${requestId}/conflict_groups/${conflictGroupId}/messages`,
    ),
  saveMessage: (
    requestId: string,
    conflictGroupId: number,
    message: IRequestMessageBE,
  ): IApiPromise<IRequestMessagesList> =>
    axios.post(
      `${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${requestId}/conflict_groups/${conflictGroupId}/messages`,
      message,
    ),
};

export const SgwRequestsApi = {
  copyAttachment: (requestId: string, params: ISgwRequestCopyAttachment): IApiPromise<void> =>
    axios.post(`${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${requestId}/sgw_attachments`, {
      ...params,
      copy: true,
    }),
  deleteAttachment: (requestId: string, attachmentId: string): IApiPromise<void> =>
    axios.delete(`${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${requestId}/sgw_attachments/${attachmentId}`),
  export: (params: IPagedApiParams & ISgwRequestsFilter): IApiPromise<void> =>
    axios.get(`${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/exports`, { params }),
  fetchAttachments: (id: string): IApiPromise<any> =>
    axios.get(`${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${id}/sgw_attachments`),
  fetchList: (params?: IPagedApiParams, fetchOnlyAdvisingParties?: boolean): IApiPromise<ISgwRequest[]> => {
    const url = fetchOnlyAdvisingParties
      ? `${CONFIG.backOfficeApi}${ADVISING_PARTIES_SGW_REQUEST_URL}`
      : `${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}`;
    return axios.get(url, { params });
  },
  fetchRequestCounters(params?: ISgwRequestCountersFilter): IApiPromise<ISgwRequestCounters> {
    return axios.get(`${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/count`, {
      params,
    });
  },
  fetch: (id: string): IApiPromise<ISgwRequest> => axios.get(`${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${id}`),
  patch: (request: Partial<ISgwRequestBE>): IApiPromise<ISgwRequest> =>
    axios.patch(`${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${request.id}`, request),
  fetchPhases: (id: string): IApiPromise<ISgwPhase[]> =>
    axios.get(`${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${id}/phases`),

  fetchSgwRequestMessages: (id: string): IApiPromise<IRequestMessagesList> =>
    axios.get(`${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${id}/messages`),
  saveSgwRequestMessage: (requestId: string, message: IRequestMessageBE): IApiPromise<IRequestMessagesList> =>
    axios.post(`${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${requestId}/messages`, message),
  resolveSgwRequestMessages: (requestId: string): IApiPromise<IRequestMessagesList> =>
    axios.post(`${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${requestId}/messages/resolve`, {}),
  saveAttachments: (
    requestId: string,
    {
      id,
      category,
      starred,
      sgwPhases,
      fileName,
      fileStorage,
      quarterYear,
      quarterIndex,
    }: Partial<ISgwRequestAttachment>,
  ): IApiPromise<ISgwRequestAttachment> => {
    const data = {
      category,
      sgwPhases,
      starred,
      quarterYear,
      quarterIndex,
      fileName: fileName || fileStorage?.fileName,
    };
    return id
      ? axios.patch(`${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${requestId}/sgw_attachments/${id}`, data)
      : axios.post(`${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${requestId}/sgw_attachments`, data);
  },
  savePhase: (requestId: string, { id, ...phase }: Partial<ISgwPhase>): IApiPromise<ISgwPhase> =>
    id
      ? axios.patch(`${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${requestId}/phases/${id}`, phase)
      : axios.post(`${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${requestId}/phases`, phase),
  patchInternalNotes: ({ id, backofficeNotes }: ISgwRequestPatchInternalNotes): IApiPromise<ISgwRequest> =>
    axios.patch(`${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${id}`, { backofficeNotes }),
  postTransitionState: ({ id, transition }: ISgwRequestPostTransition): IApiPromise<ISgwRequest> =>
    axios.post(`${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${id}/transition/${transition}`),
  sendEmailPermit: (id: string): IApiPromise<void> =>
    axios.post(`${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${id}/permit/email`),
  sendFinalInvoice: (id: string): IApiPromise<void> =>
    axios.post(`${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${id}/send_final_invoice`),
  conflicts: conflicts,
  fetchQuarterCost: (id: string): IApiPromise<ISgwQuarterCost[]> =>
    axios.get(`${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${id}/cost`),
  removePhase: (requestId: number, id: string): IApiPromise<ISgwPhase> =>
    axios.delete(`${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${requestId}/phases/${id}`),
  clonePhase: (requestId: string, phaseId: number, phase: Partial<ISgwPhase>): IApiPromise<ISgwPhase> =>
    axios.post(`${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${requestId}/phases/${phaseId}/clone`, phase),
  fetchSgwRequestHistory: (requestId: string, sorting: ISorting): IApiPromise<ISgwHistory[]> =>
    axios.get(`${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${requestId}/history`, {
      params: {
        sort: sorting.key,
        order: sorting.direction,
      },
    }),
  fetchSgwRequestPermitHistory: (id: string): IApiPromise<ISgwRequestPermit[]> =>
    axios.get(`${CONFIG.backOfficeApi}${BASE_SGW_REQUEST_URL}/${id}/permithistory`),
};
