import axios from 'axios';
import { CONFIG, IAddress, IApiPromise, ISortingDirection } from '../../common';
import {
  ICheckIban,
  IFeature,
  IRequest,
  IRequestExtensionPatch,
  IRequestFormData,
  IRequestLocation,
  IRequestLocationDetail,
  IRequestLocationPatch,
  IRequestMessageBE,
  IRequestMessagesList,
  IRequestMetadata,
  IRequestOverview,
  IRequestPatchApprove,
  IRequestPatchAttachment,
  IRequestPatchCluster,
  IRequestPatchInternalNotes,
  IRequestPatchPriority,
  IRequestPatchPublicDomainIntakes,
  IRequestPatchReject,
  IRequestPatchUserResponsible,
  IRequestReason,
  IRequestReasonFilter,
  IRequestsApi,
  IRequestsHistory,
  IRequestsHistoryApi,
  IRequestsPermitHistory,
  RequestStates,
} from '../../types';

export const RequestsApi = {
  exportRequests: (params: IRequestsApi): IApiPromise<IRequestOverview[]> => {
    return axios.post(`${CONFIG.backOfficeApi}/v2/requests/exports`, params);
  },

  getRequest: (id: number): IApiPromise<IRequest> => {
    return axios.get(`${CONFIG.backOfficeApi}/v2/requests/${id}`);
  },

  deleteRequest: (id: string): IApiPromise<void> => {
    return axios.delete(`${CONFIG.backOfficeApi}/v2/requests/${id}`);
  },

  postRequest: (requestData: IRequestFormData): IApiPromise<IRequest> => {
    const requestAddress: IAddress = {
      city: requestData.requestCity,
      zipCode: requestData.requestZipCode,
      street: requestData.requestStreet,
      streetNumber: requestData.requestStreetNumber,
    };
    return axios.post(`${CONFIG.backOfficeApi}/v2/requests`, {
      ...requestData,
      country: { country: requestData.country },
      requestAddress,
    });
  },

  putRequest: (id: string, requestData: IRequestFormData): IApiPromise<IRequest> => {
    return axios.put(`${CONFIG.backOfficeApi}/v2/requests/${id}`, {
      ...requestData,
      country: { country: requestData.country },
    });
  },

  patchRequestAttachment: ({ id, attachments }: IRequestPatchAttachment): IApiPromise<IRequest> => {
    return axios.patch(`${CONFIG.backOfficeApi}/v2/requests/${id}`, { attachments });
  },

  patchRequestPriority: ({ id, priority }: IRequestPatchPriority): IApiPromise<IRequest> => {
    return axios.patch(`${CONFIG.backOfficeApi}/v2/requests/${id}`, { priority });
  },

  patchRequestCluster: ({ id, cluster }: IRequestPatchCluster): IApiPromise<IRequest> => {
    return axios.patch(`${CONFIG.backOfficeApi}/v2/requests/${id}`, { cluster });
  },

  patchRequestUserResponsible: ({ id, ...params }: IRequestPatchUserResponsible): IApiPromise<IRequest> => {
    return axios.patch(`${CONFIG.backOfficeApi}/v2/requests/${id}`, params);
  },

  patchRequestState: (id: string, state: RequestStates, params: Object = {}): IApiPromise<IRequest> => {
    return axios.patch(`${CONFIG.backOfficeApi}/v2/requests/${id}`, { ...params, state });
  },

  resetPincode: (id: string): IApiPromise<IRequest> =>
    axios.patch(`${CONFIG.backOfficeApi}/v2/requests/${id}/reset_pincode`),

  patchRequestPublicDomainIntakes: ({
    id,
    publicDomainIntakes,
  }: IRequestPatchPublicDomainIntakes): IApiPromise<IRequest> => {
    return axios.patch(`${CONFIG.backOfficeApi}/v2/requests/${id}`, { publicDomainIntakes });
  },

  patchRequestApprove: ({ id, reasonForApproval }: IRequestPatchApprove): IApiPromise<IRequest> => {
    return RequestsApi.patchRequestState(id, RequestStates.approved, { reasonForApproval });
  },

  patchRequestReject: ({ id, reasonForRejection }: IRequestPatchReject): IApiPromise<IRequest> => {
    return RequestsApi.patchRequestState(id, RequestStates.rejected, { reasonForRejection });
  },

  patchRequestReview: (id: string): IApiPromise<IRequest> => {
    return RequestsApi.patchRequestState(id, RequestStates.reviewing);
  },

  patchRequestWaitingForPayment: (id: string): IApiPromise<IRequest> =>
    RequestsApi.patchRequestState(id, RequestStates.waiting_for_payment),

  patchRequestExtension: ({
    id,
    reasonForApproval,
    reasonForRejection,
    extensionId,
    state,
  }: IRequestExtensionPatch): IApiPromise<IRequest> => {
    let data: any = { state };
    if (reasonForApproval) {
      data = { ...data, reasonForApproval };
    } else if (reasonForRejection) {
      data = { ...data, reasonForRejection };
    }
    return axios.patch(`${CONFIG.backOfficeApi}/v2/requests/${id}/extensions/${extensionId}`, data);
  },

  patchRequestCancellation: (id: string): IApiPromise<IRequest> => {
    return RequestsApi.patchRequestState(id, RequestStates.canceled);
  },

  getRequestList: (params: IRequestsApi): IApiPromise<IRequestOverview[]> => {
    return axios.get(`${CONFIG.backOfficeApi}/v2/requests`, { params });
  },

  getRequestReasons: (): IApiPromise<IRequestReason[]> => {
    return axios.get(`${CONFIG.backOfficeApi}/v2/requests/reasons`);
  },

  getRequestReasonFilters: (): IApiPromise<IRequestReasonFilter[]> => {
    return axios.get(`${CONFIG.backOfficeApi}/v2/requests/reason_filters`);
  },

  getRequestMessages: (id: string): IApiPromise<IRequestMessagesList> => {
    return axios.get(`${CONFIG.backOfficeApi}/v2/requests/${id}/messages`);
  },

  getRequestHistory: ({ all, id, sorting }: IRequestsHistoryApi): IApiPromise<IRequestsHistory[]> => {
    return axios.get(`${CONFIG.backOfficeApi}/v2/requests/${id}/history`, {
      params: {
        all,
        sort: sorting.key,
        order: sorting.direction,
      },
    });
  },

  getRequestPermitHistory: ({ id, sorting }: IRequestsHistoryApi): IApiPromise<IRequestsPermitHistory[]> => {
    return axios.get(`${CONFIG.backOfficeApi}/v2/requests/${id}/permithistory`, {
      params: {
        sort: sorting.key,
        order: sorting.direction,
      },
    });
  },

  postSendApprovedMail: (id: string): IApiPromise<IRequestMessagesList> => {
    return axios.post(`${CONFIG.backOfficeApi}/v2/requests/${id}/send_approved_mail`);
  },

  postSendRetributionMail: (id: string): IApiPromise<IRequest> => {
    return axios.post(`${CONFIG.backOfficeApi}/v2/requests/${id}/send_retribution_mail`);
  },

  saveRequestMessage: (requestId: string, message: IRequestMessageBE): IApiPromise<IRequestMessagesList> =>
    axios.post(`${CONFIG.backOfficeApi}/v2/requests/${requestId}/messages`, message),
  resolveRequestMessages: (requestId: string): IApiPromise<IRequestMessagesList> => {
    return axios.post(`${CONFIG.backOfficeApi}/v2/requests/${requestId}/messages/resolve`, {});
  },

  getMetadataList: (): IApiPromise<IRequestMetadata> => {
    return axios.get(`${CONFIG.backOfficeApi}/v1/metadata/request`);
  },

  getConflicts: (id: string): IApiPromise<{ feature: IFeature }[]> => {
    return axios.get(`${CONFIG.backOfficeApi}/v2/requests/${id}/conflicts`);
  },

  getDrafts: (createdById: number): IApiPromise<{ feature: IFeature }[]> => {
    return axios.get(`${CONFIG.backOfficeApi}/v2/requests`, {
      params: {
        sort: 'referenceId',
        order: ISortingDirection.desc,
        page_size: 100,
        states: [RequestStates.draft],
        created_by: createdById,
      },
    });
  },

  getExtensions: (id: string): IApiPromise<IRequest> => {
    return axios.get(`${CONFIG.backOfficeApi}/v2/requests/${id}/extensions`);
  },

  getEditableFields: (id: string): IApiPromise<IRequest> => {
    return axios.get(`${CONFIG.backOfficeApi}/v2/requests/${id}/editableFormEntries`);
  },

  postPermitRegenerate: (id: string): IApiPromise<void> => {
    return axios.post(`${CONFIG.backOfficeApi}/v2/requests/${id}/permit/regenerate`);
  },

  patchRequestInternalNotes: ({ id, backofficeNotes }: IRequestPatchInternalNotes): IApiPromise<IRequest> => {
    return axios.patch(`${CONFIG.backOfficeApi}/v2/requests/${id}`, { backofficeNotes });
  },

  patchRequestLocation: ({ id, locationId, ...patchData }: IRequestLocationPatch): IApiPromise<IRequest> => {
    return axios.patch(`${CONFIG.backOfficeApi}/v2/requests/${id}/locations/${locationId}`, patchData);
  },

  fetchLocationDetails: ({ id, locationId }: IRequestLocation): IApiPromise<IRequestLocationDetail> => {
    return axios.get(`${CONFIG.backOfficeApi}/v2/requests/${id}/locations/${locationId}`);
  },

  checkIban: (iban: string): IApiPromise<ICheckIban> => {
    return axios.get(`${CONFIG.generalApi}/v1/check_iban?iban=${iban}`);
  },

  workingDaysCalculation: (date: string, amountOfDays: number, returnLastWorkingDay: boolean = false, stopBeforeWeekend: boolean = false): IApiPromise<string> =>
    axios.get(`${CONFIG.generalApi}/v1/working_days`, {
      params: {
        date,
        amountOfDays,
        returnLastWorkingDay,
        stopBeforeWeekend,
      },
    }),
};
