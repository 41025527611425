import { IWithId } from './generic.types';
import { IGeometry } from './geojson.types';
import { PathOptions } from 'leaflet';
import { COLORS } from '../common';

export interface ISgwGeoDrawing {
  id?: number;
  customName?: string;
  geoDrawingType: GeoDrawingType;
  geometry: IGeometry;
  sgwPhaseId: number;
  surfaceArea?: number;
  sgwTrafficMeasures: number[];
}

export interface ISgwPhase extends IWithId {
  addresses: IPhaseAddress[];
  locationDescription?: string;
  sgwGeoDrawings: ISgwGeoDrawing[];
  sgwNatureOfWorks: number[];
  phaseName?: string;
  dateFrom: string;
  dateUntil: string;
  extendedSurface?: number;
  extendedDuration?: number;
  timeFrom: string;
  timeUntil: string;
  trafficMeasureClarification?: string;
  workingHoursNotStandard?: boolean;
  workingHoursFrom: string;
  workingHoursUntil: string;
  exemptedSurfaceArea: number;
  isRecurringPhaseTemplate: boolean;
  permitRequestId?: number;
  parentPhaseId?: number;
}

export interface ISgwPhaseWithRecurringPhases extends ISgwPhase {
  recurringPhases?: ISgwPhase[];
}

export interface IPhaseAddress extends IWithId {
  streetName: string;
  streetNumberFrom?: string;
  streetNumberTo?: string;
}

export enum GeoDrawingType {
  constructionzone = 'constructionzone',
  parkingban = 'parkingban',
  detour = 'detour',
}

export const getPathOptions = (type: GeoDrawingType, isDisabled?: boolean): PathOptions => {
  switch (type) {
    case GeoDrawingType.constructionzone:
      return {
        color: COLORS.APPLE,
        opacity: isDisabled ? 0.3 : 1,
      };
    case GeoDrawingType.detour:
      return {
        color: COLORS.BEER,
        opacity: isDisabled ? 0.3 : 1,
      };
    case GeoDrawingType.parkingban:
      return {
        color: COLORS.PRIMARY,
        opacity: isDisabled ? 0.3 : 1,
      };
  }
};
