import { Tooltip } from '@material-ui/core';
import * as React from 'react';
import { createUseStyles } from 'react-jss';
import { translate } from '../../../common/translations/translate';
import { MessageState } from '../../../types';
import messageBlack from '../../../assets/images/message-black.svg';
import messageGreen from '../../../assets/images/message-green.svg';
import messageOrange from '../../../assets/images/message-orange.svg';

const useStyles = createUseStyles({
  img: {
    height: '20px',
  },
});

interface IProps {
  messageState?: MessageState;
}

export const FeedbackIcon = ({ messageState }: IProps) => {
  const C = useStyles();

  if (!messageState || !MessageState[messageState] || messageState === MessageState.not_in_feedback_loop) {
    return null;
  }

  let messageUrl;
  let title;
  switch (messageState) {
    case MessageState.awaiting_feedback:
      messageUrl = messageGreen;
      title = translate('awaitingFeedback');
      break;
    case MessageState.feedback_received:
      messageUrl = messageOrange;
      title = translate('feedbackReceived');
      break;
    default:
      messageUrl = messageBlack;
      title = translate('feedbackStatus');
      break;
  }

  return (
    <Tooltip title={title} aria-label={messageState}>
      <img className={C.img} src={messageUrl} alt={title} />
    </Tooltip>
  );
};

FeedbackIcon.Header = () => <FeedbackIcon messageState={MessageState.header} />;
