import { Grid } from '@material-ui/core';
import React, { FC } from 'react';
import { translate } from '../../../common/translations/translate';
import { useStyles } from '../../../style/RetributionTable.styles';

export const RetributionTableHeaderForUtilityWorks: FC = () => {
  const C = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item container xs={11} justify="flex-end">
        <Grid item xs={4} className={C.header}>
          {translate('sgw.requests.detail.retributionTable.columns.phaseName')}
        </Grid>
        <Grid item xs={3} className={C.header}>
          {translate('sgw.requests.detail.retributionTable.columns.phaseDaysInQuarterForUtilityWorks')}
        </Grid>
        <Grid item xs={2} className={C.header} direction="column">
          {translate('sgw.requests.detail.retributionTable.columns.surface')}
        </Grid>
        <Grid item xs={2} className={C.header} direction="column">
          <span>{translate('sgw.requests.detail.retributionTable.columns.tarifForUtilityWorks')}</span>
        </Grid>
      </Grid>
      <Grid item container xs={1}>
        <Grid item className={C.header} xs={12}>
          {translate('sgw.requests.detail.retributionTable.columns.totals')}
        </Grid>
      </Grid>
    </Grid>
  );
};
