import { Grid } from '@material-ui/core';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Visible } from '../../../common/components/layout/Visible.component';
import { translate } from '../../../common/translations/translate';
import { getDatesOfQuarter } from '../../../common/utils/quarter.util';
import { getCostQuarterPhases } from '../../../store/selectors/sgwRequest.selectors';
import { ISgwQuarterCost } from '../../../types/SgwQuarterCost.types';
import { useStyles } from '../../../style/RetributionTable.styles';
import { RetributionTablePhase } from './RetributionTablePhase';

export const RetributionTableQuarter: FC<ISgwQuarterCost & { hasCorrections: boolean }> = ({
  invoiced,
  quarterCost,
  quarterIndex,
  quarterYear,
  phases,
  quarterCorectionCost,
  correctionInvoiced,
  hasCorrections,
}) => {
  const C = useStyles();

  const costQuarterPhases = useSelector(getCostQuarterPhases(phases));

  return (
    <>
      <Grid container spacing={2}>
        <Grid item container xs={hasCorrections ? 10 : 11}>
          <Grid item xs={1} className={invoiced ? C.invoicedQuarter : C.quarter}>
            <b>{`${translate('sgw.requests.detail.retributionTable.quarter')} ${quarterIndex}`}</b>
          </Grid>
          <Grid item xs={8} className={invoiced ? C.invoicedQuarter : C.quarter}>
            <b>{getDatesOfQuarter(quarterYear, quarterIndex)}</b>
          </Grid>
          <Grid item xs={3} className={invoiced ? C.invoicedQuarter : C.quarter} style={{ textAlign: 'right' }}>
            <b>{invoiced ? translate('sgw.requests.detail.retributionTable.invoiced') : ''}</b>
          </Grid>
        </Grid>
        <Grid item container xs={1}>
          <Grid item xs={12} className={invoiced ? C.invoicedQuarterTotal : C.quarterTotal}>
            <span>{`${translate('sgw.requests.detail.retributionTable.currency')} ${quarterCost.toFixed(2)}`}</span>
          </Grid>
        </Grid>

        <Visible visible={!!quarterCorectionCost}>
          <Grid item container xs={1}>
            <Grid item xs={12} className={correctionInvoiced ? C.invoicedQuarterTotal : C.quarterTotal}>
              <span>{`${translate('sgw.requests.detail.retributionTable.currency')} ${quarterCorectionCost?.toFixed(
                2,
              )}`}</span>
            </Grid>
          </Grid>
        </Visible>
      </Grid>
      {costQuarterPhases.map((phase, index) => (
        <>
          <RetributionTablePhase hasCorrections={hasCorrections} {...phase} index={index} />
          {phase.recurringPhases.map((phase, index) => (
            <RetributionTablePhase hasCorrections={hasCorrections} {...phase} index={index} recurringPhase />
          ))}
        </>
      ))}
    </>
  );
};
