import React, { FC } from 'react';
import { ISgwPermitConditionsAclActions } from '../../../../../../types';
import { Auth } from '../../../../../atoms';
import { PermitConditionForm } from '../../../../../forms';
import { appUrls } from '../../../../../../common/config/url.constants';
import { Container } from '../../../../../../common';
import { translate } from '../../../../../../common/translations/translate';
import { useFormStyles } from '../../../../../../style/form.styles';
import { Header } from '../../../../../../common/components/layout/Header.component';

export const EditPermitConditionPage: FC = () => (
  <Auth acl={ISgwPermitConditionsAclActions.editSgwPermitConditions} showUnauthorizedPage>
    <Header backButtonUrl={appUrls.sgw.manage.permitConditions.base}>
      {translate('sgw.permitConditions.updatePermitCondition')}
    </Header>
    <Container className={useFormStyles().container}>
      <PermitConditionForm title={translate('sgw.permitConditions.details')} />
    </Container>
  </Auth>
);
