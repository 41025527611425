import { WorkOrderItemTypes } from '../../../planning/planning.constants';
import { createUseStyles } from 'react-jss';
import { COLORS } from '../../../common';
import * as React from 'react';
import { FC } from 'react';
import classNames from 'classnames';
import { getWorkOrderItemTypeIcon } from '../../../planning/utils/work-order-item-type-icon';
import fence from '../../../assets/images/fence.svg';

interface IProps {
  type: WorkOrderItemTypes;
}

const useStyles = createUseStyles({
  default: {
    fontSize: 16,
    color: COLORS.GREY,
  },
  red: {
    fontSize: 16,
    color: COLORS.RED,
  },
});

export const WorkOrderItemTypeIcon: FC<IProps> = ({ type }) => {
  const C = useStyles();

  switch (type) {
    case WorkOrderItemTypes.pick_up:
    case WorkOrderItemTypes.drop_off:
    case WorkOrderItemTypes.update:
    case WorkOrderItemTypes.replace:
    case WorkOrderItemTypes.notification:
      return <i className={classNames('material-icons', C.default)}>{getWorkOrderItemTypeIcon(type)}</i>;
    case WorkOrderItemTypes.pickup_overdue:
      return <i className={classNames('material-icons', C.red)}>{getWorkOrderItemTypeIcon(type)}</i>;
    case WorkOrderItemTypes.pick_up_construction_zone_signage:
      return <img className={C.default} src={fence} alt={WorkOrderItemTypes.pick_up_construction_zone_signage} />;
    case WorkOrderItemTypes.retrieve:
    default:
      return <i className={classNames('material-icons', C.default)}>location_searching</i>;
  }
};
