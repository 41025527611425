import { IPagedApiParams } from '../common';
import { IPagedTableState, IWithId } from './generic.types';

export interface ISgwPermitCondition extends IWithId {
  active: boolean;
  category: SgwPermitConditionCategory;
  description: string;
  referenceCode: string;
  title: string;
}

export interface ISgwPermitConditionsState {
  list: IPagedTableState<ISgwPermitCondition, ISgwPermitConditionsFilter>;
  loading: boolean;
  existingReferenceCodes: string[];
  existingTitles: string[];
}

export interface ISgwPermitConditionsFilter {
  category?: SgwPermitConditionCategory[];
  query?: string;
  referenceCode?: string;
  active?: boolean;
}

export interface ISgwPermitConditionsPagedApiParams extends IPagedApiParams {
  title?: string;
  referenceCode?: string;
}

export enum SgwPermitConditionCategory {
  parkingOrStoppageBan = 'parkingOrStoppageBan',
  specificConditions = 'specificConditions',
  considerationPointForScaffoldUsage = 'considerationPointForScaffoldUsage',
  considerationPointForLiftUsage = 'considerationPointForLiftUsage',
  considerationPointForContainerUsage = 'considerationPointForContainerUsage',
  considerationPointForCraneUsage = 'considerationPointForCraneUsage',
  specialMeasuresForPedestrians = 'specialMeasuresForPedestrians',
  specialMeasuresForPedestriansAndOrCyclists = 'specialMeasuresForPedestriansAndOrCyclists',
  specialMeasuresForCyclists = 'specialMeasuresForCyclists',
  specialMeasuresForPublicTransport = 'specialMeasuresForPublicTransport',
  specialMeasuresForCars = 'specialMeasuresForCars',
  constructionSiteConditions = 'constructionSiteConditions',
}
