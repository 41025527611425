import axios from 'axios';
import { CONFIG, IApiPromise, IPagedApiParams } from '../../common';
import { INotification, INotificationCount, INotificationHistoryItem } from '../types/notification';

export const NotificationApi = {
  getNotification: (hash: string): IApiPromise<INotification> =>
    axios.get(`${CONFIG.backOfficeApi}/v1/civilian_notifications/${hash}`),
  getNotificationHistory: (id: string): IApiPromise<INotificationHistoryItem[]> =>
    axios.get(`${CONFIG.backOfficeApi}/v1/civilian_notifications/${id}/history`),
  getNotificationCount: (): IApiPromise<INotificationCount> =>
    axios.get(`${CONFIG.backOfficeApi}/v1/app_notifications`),
  patchNotification: (notification: INotification): IApiPromise<INotification> => {
    const { attachments, ...notificationData } = notification;
    // Transform INotification to proper API request data
    if (notificationData.userResponsible) {
      // @ts-ignore
      notificationData.userResponsible = notification.userResponsible.id;
    }
    return axios.patch(`${CONFIG.backOfficeApi}/v1/civilian_notifications/${notification.id}`, notificationData);
  },
  fetchList: (params: IPagedApiParams): IApiPromise<INotification[]> =>
    axios.get(`${CONFIG.backOfficeApi}/v1/civilian_notifications`, { params }),
};
