import { Grid, makeStyles } from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MultiSelect } from '../../../common';
import { CheckboxWithLabel } from '../../../common/components/form-fields/checkboxWithLabel.component';
import { ATOMS } from '../../../common/config/atom.constants';
import { translate } from '../../../common/translations/translate';
import { ISgwRequestsFilter } from '../../../types';
import { SgwRequestActions } from '../../../store/actions';
import { selectTable } from '../../../store/selectors/sgwRequest.selectors';
import { useRequestMetadata } from '../../../hooks';
import { useAtom } from 'jotai';
import { selectUser } from '../../../store/selectors';
import { ExportResultsButton } from '../../atoms';

const useStyles = makeStyles({
  container: {
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  title: {
    paddingBottom: '14px',
  },
  myRequests: {
    paddingBottom: '0',
    width: 'fit-content',
  },
  clusters: {
    width: '20%',
  },
});

export const RequestListOverviewTitle: FC = () => {
  const C = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const metadata = useRequestMetadata();
  const { filters: _filters } = useSelector(selectTable);
  const [filters, setFilters] = useAtom(ATOMS.sgwRequest.overview.filtersAtom);
  const { myRequests, clusters } = filters;

  useEffect(() => {
    setFilters(_filters);
  }, [_filters, setFilters]);

  const fetchRequests = (newFilter: ISgwRequestsFilter) =>
    dispatch(
      SgwRequestActions.list.fetch({
        filters: {
          ...filters,
          ...newFilter,
        },
      }),
    );

  const onChangeMyRequests = () => user && fetchRequests({ myRequests: !myRequests ? `${user.id}` : undefined });

  const clusterFilterChange = (clusters: string[]) => fetchRequests({ clusters });

  const exportRequests = () => dispatch(SgwRequestActions.export());

  return (
    <Grid container spacing={2} className={C.container}>
      <Grid item xs={5} className={C.title}>
        {translate('sgw.requests.dashboard.title')}
      </Grid>
      <Grid item xs={2}>
        <CheckboxWithLabel
          className={C.myRequests}
          label={translate('sgw.requests.dashboard.myRequests')}
          checked={!!myRequests}
          onChange={onChangeMyRequests}
        />
      </Grid>
      {!!metadata?.clusters.length && (
        <Grid item xs={3}>
          <MultiSelect
            data={metadata.clusters}
            value={clusters || []}
            valueChange={clusterFilterChange}
            valueProp="cluster"
            keyProp="cluster"
            displayProp="name"
            id="requestClusterFilter"
            label={translate('sgw.requests.dashboard.selectCluster')}
          />
        </Grid>
      )}
      <Grid item>
        <ExportResultsButton onExport={exportRequests} />
      </Grid>
    </Grid>
  );
};
