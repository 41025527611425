import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import * as I18n from 'i18n-js';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '../../../common/components/buttons/asign-button-extensions';
import { Snackbar } from '../../../common/components/feedback/SnackBar.component';
import { Visible } from '../../../common/components/layout/Visible.component';
import { ExportResultsButton } from '../../../components/atoms';
import { useAuthorization } from '../../../hooks';
import { selectRequestExporting } from '../../../store/selectors';
import { IRequestAclActions } from '../../../types';

const useStyles = makeStyles({
  root: {
    marginBottom: 18,
    width: '100%',
    justifyContent: 'space-between',
    display: 'flex',
  },
});

interface IProps {
  onExport: () => void;
  onSnackbarClose: () => void;
  onNewRequest: () => void;
}

export const ListHeader: FunctionComponent<IProps> = ({ onExport, onSnackbarClose, onNewRequest }) => {
  const C = useStyles();
  const exportingSuccess = useSelector(selectRequestExporting);
  const { isAuthorized: canCreateRequest } = useAuthorization(IRequestAclActions.editRequestDraft);
  return (
    <div className={C.root} data-testid="requestListHeader">
      <Visible visible={canCreateRequest}>
        <Button.Green dataTestId="newRequestButton" onClick={onNewRequest} startIcon={<AddIcon fontSize={'small'} />}>
          {I18n.t('Requests.Header.NewRequest')}
        </Button.Green>
      </Visible>
      <ExportResultsButton onExport={onExport} />
      <Snackbar duration={5000} onClose={onSnackbarClose} show={exportingSuccess}>
        {I18n.t('Requests.Header.ExportSuccess')}
      </Snackbar>
    </div>
  );
};
