import { useCallback, useEffect, useState } from 'react';
import moment from 'moment/moment';
import { RequestsApi } from '../store/api';
import { useSelector } from 'react-redux';
import { getSettings, selectUser } from '../store/selectors';
import { DateFormat } from '../types';

export const useLatestValidEndDate = (dateFrom?: string) => {
  const currentUser = useSelector(selectUser);
  const settings = useSelector(getSettings);
  const createRequestWithoutLimitations = currentUser?.acl.createRequestWithoutLimitations;
  const [latestEndDate, setLatestEndDate] = useState(moment());

  const fetchValidEndDate = useCallback(
    async (dateFromToVerify?: string) => {
      if (!createRequestWithoutLimitations && settings?.UTILITY_COMPANY_START_DATE_BUFFER) {
        const earliestStartDate = dateFromToVerify
          ? moment(dateFromToVerify)
          : moment().add(settings.UTILITY_COMPANY_START_DATE_BUFFER, 'days');

        try {
          const response = await RequestsApi.workingDaysCalculation(
            earliestStartDate.format(DateFormat.reduxStoreDateString),
            settings.UTILITY_COMPANY_MAX_LENGTH_OF_WORKS,
            true,
            true
          );
          return moment(response.data.data);
        } catch (error) {
          console.error('Error fetching working days:', error);
        }
      }
    },
    [
      createRequestWithoutLimitations,
      settings.UTILITY_COMPANY_MAX_LENGTH_OF_WORKS,
      settings.UTILITY_COMPANY_START_DATE_BUFFER,
    ],
  );

  useEffect(() => {
    const fetchEndDateAndSet = async () => {
      const newValidDate = await fetchValidEndDate(dateFrom);
      if (newValidDate) {
        setLatestEndDate(newValidDate);
      }
    };
    fetchEndDateAndSet();
  }, [createRequestWithoutLimitations, settings, dateFrom, fetchValidEndDate]);

  return { latestEndDate, fetchValidEndDate };
};
