import { IAddress } from '../common';
import { ICompany, IContactPerson, IPagedTableState, IPersonalInfo, IWithId } from './generic.types';

export interface ISgwPartner extends IWithId, IAddress<string>, ICompany, IContactPerson, IPersonalInfo {
  capacity: Capacity;
}

export interface ISgwPartnersState {
  loading: boolean;
  list: IPagedTableState<ISgwPartner>;
}

export enum SgwPartnerType {
  client = 'client',
  contractor = 'contractor',
  fee = 'fee',
  requester = 'requester',
  signalisation = 'signalisation',
}

export type IByPartnerType<T> = {
  [key in SgwPartnerType]: T;
};

export enum Capacity {
  Business = 'business',
  BelgianResident = 'belgian_resident',
  NonBelgianResident = 'non_belgian_resident',
}
