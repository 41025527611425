import { IWorkOrderItem } from '../../../types';
import { WorkOrderItemTypes } from '../../planning.constants';
import {
  ArrowDownward,
  ArrowUpward,
  CompareArrows,
  Create,
  LocationSearching,
  Notifications,
} from '@material-ui/icons';
import React, { FC } from 'react';
import fence from '../../../assets/images/fence.svg';
import { createUseStyles } from 'react-jss';

interface IProps {
  woi: IWorkOrderItem;
}

const useStyles = createUseStyles({
  fence: {
    marginRight: '5px',
  },
});

export const WorkOrderItemIcon: FC<IProps> = ({ woi }) => {
  const C = useStyles();

  switch (woi.type) {
    case WorkOrderItemTypes.pick_up:
      return <ArrowUpward fontSize="small" />;
    case WorkOrderItemTypes.drop_off:
      return <ArrowDownward fontSize="small" />;
    case WorkOrderItemTypes.retrieve:
      return <LocationSearching fontSize="small" />;
    case WorkOrderItemTypes.replace:
      return <CompareArrows fontSize="small" />;
    case WorkOrderItemTypes.update:
      return <Create fontSize="small" />;
    case WorkOrderItemTypes.notification:
      return <Notifications fontSize="small" />;
    case WorkOrderItemTypes.pick_up_construction_zone_signage:
      return <img className={C.fence} src={fence} alt="fence" />;
    default:
      return null;
  }
};
