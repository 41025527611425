import * as React from 'react';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Container, FormattedDateTime, IPaging, ISorting } from '../../common';
import { SyncButton } from '../../common/components/buttons/SyncButton.component';
import { GenericTablePanel } from '../../common/components/table/GenericTablePanel.component';
import { appUrls } from '../../common/config/url.constants';
import { CarFreeZonesActions } from '../../common/store/car-free-zones/carFreeZones.actions';
import {
  getList,
  selectCarFreeZonesLoading,
  selectGatesIsSyncing,
  selectTable,
} from '../../common/store/car-free-zones/carFreeZones.selectors';
import { translate } from '../../common/translations/translate';
import { Auth } from '../../components';
import { useGeneralStyles } from '../../style/generalStyles';
import {
  CarFreeZoneFilterKey,
  CarFreeZonesFilterType,
  ICarFreeZone,
  ICarFreeZoneAclActions,
  ICarFreeZonesFilter,
} from '../../types';
import { carFreeZonesInitialFilter } from '../CarFreeZone.constants';
import { CarFreeZonesFilter } from './CarFreeZonesFilter.component';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    paddingTop: 20,
  },
});

export const CarFreeZonesListPage: FunctionComponent = () => {
  const C = useStyles();
  const G = useGeneralStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { filters, paging, sorting } = useSelector(selectTable);
  const loading = useSelector(selectCarFreeZonesLoading);
  const zones = useSelector(getList);
  const isSyncingGates = useSelector(selectGatesIsSyncing);

  useEffect(() => {
    dispatch(CarFreeZonesActions.list.fetch({}));
  }, [dispatch]);

  const onChangeSorting = useCallback(
    (sorting: ISorting) => {
      dispatch(CarFreeZonesActions.list.fetch({ sorting }));
    },
    [dispatch],
  );

  const onChangePaging = useCallback(
    (paging: IPaging) => {
      dispatch(CarFreeZonesActions.list.fetch({ paging }));
    },
    [dispatch],
  );

  const onChangeFiltering = useCallback(
    (filters: ICarFreeZonesFilter): void => {
      dispatch(CarFreeZonesActions.list.fetch({ filters }));
    },
    [dispatch],
  );

  const resetFilters = useCallback((): void => {
    dispatch(CarFreeZonesActions.list.fetch({ filters: carFreeZonesInitialFilter }));
  }, [dispatch]);

  const onClick = useCallback(
    (carFreeZone: ICarFreeZone) => {
      history.push(`${appUrls.carFreeZones.krautliZones.base}/${carFreeZone.id}`);
    },
    [history],
  );

  const onSyncGates = useCallback((): void => {
    dispatch(CarFreeZonesActions.gates.sync());
  }, [dispatch]);

  return (
    <Container className={C.container}>
      <Auth acl={ICarFreeZoneAclActions.viewCarFreeZoneList}>
        <GenericTablePanel
          customHeader={
            <CarFreeZonesFilter changeFilters={onChangeFiltering} filters={filters} resetFilters={resetFilters} />
          }
          dataTestId="CarFreeZoneListTable"
          labelUnavailable={translate('carFreeZones.krautli.unavailable')}
          loading={loading}
          onChangeSorting={onChangeSorting}
          onChangePaging={onChangePaging}
          onClick={onClick}
          paging={paging}
          sorting={sorting}
          records={zones}
          title={
            <div className={G.flexRowSpaceBetween}>
              {translate('carFreeZones.krautli.list.title')}
              <Auth acl={ICarFreeZoneAclActions.syncCarFreeZoneGates}>
                <SyncButton isSyncing={isSyncingGates} onClick={onSyncGates} dataTestId="CarFreeZonesSyncGates">
                  {translate('carFreeZones.krautli.list.syncGates')}
                </SyncButton>
              </Auth>
            </div>
          }
          columnKey="id"
          columns={[
            {
              label: translate('carFreeZones.krautli.props.id'),
              name: 'id',
              sortable: true,
            },
            {
              label: translate('carFreeZones.krautli.props.name'),
              name: 'name',
              sortable: true,
            },
            {
              label: translate('carFreeZones.krautli.props.gisId'),
              name: 'gisId',
              sortable: true,
            },
            {
              label: translate('carFreeZones.krautli.props.type'),
              name: 'type',
              sortable: true,
              renderer: (type: string) =>
                type in CarFreeZonesFilterType
                  ? translate(`carFreeZones.krautli.list.filters.type.options.${type as CarFreeZoneFilterKey}`)
                  : type,
            },
            {
              label: translate('carFreeZones.krautli.props.numberOfGates'),
              name: 'numberOfGates',
              sortable: true,
              renderer: (numberOfGates) => numberOfGates || '',
            },
            {
              label: translate('carFreeZones.krautli.props.numberOfActiveRequests'),
              name: 'numberOfActiveRequests',
              sortable: false,
              renderer: (numberOfActiveRequests) => numberOfActiveRequests || '',
            },
            {
              label: translate('carFreeZones.krautli.props.createdAt'),
              name: 'createdAt',
              renderer: (createdAt: string) => <FormattedDateTime dateTime={createdAt} />,
              sortable: true,
            },
            {
              label: translate('carFreeZones.krautli.props.updatedAt'),
              name: 'updatedAt',
              renderer: (updatedAt: string) => <FormattedDateTime dateTime={updatedAt} />,
              sortable: true,
            },
            {
              label: translate('carFreeZones.krautli.props.active'),
              name: 'active',
              renderer: (active: boolean) => (active ? translate('yes') : translate('no')),
              sortable: true,
            },
          ]}
        />
      </Auth>
    </Container>
  );
};
