import { Grid, Paper} from '@material-ui/core';
import * as React from 'react';
import {  FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { translate } from '../../../common/translations/translate';
import { getRequestsDetails } from "../../../store/selectors";
import { PanelContent } from "../../../common/components/panel/panel-content.component";
import { LabelValue } from "../../../common/components/layout/LabelValue.component";

interface IProps {
  requestId: string;
}

export const RequestInvoiceInfo: FunctionComponent<IProps> =
  ({
     requestId
   }) => {
    const request = useSelector(getRequestsDetails(requestId));


    return (!!request?.invoice?.requestedForCompany) ? (
       <Paper square style={{ marginBottom: 20 }} data-testid="requestInfo">
        <PanelContent title={translate('Requests.Detail.InvoiceInfo.title')}>
          <Grid container spacing={2} style={{ marginTop: '20px', marginBottom: '20px' }}>
            <LabelValue label={translate('sgw.partners.columns.vatNumber')}>{request.invoice?.companyId}</LabelValue>
            <LabelValue label={translate('sgw.partners.columns.companyName')}>{request.invoice?.companyName}</LabelValue>
            <LabelValue label={translate('sgw.requests.detail.info.address')}>
              {`${request.invoice?.addressStreet}`}
              { request.invoice?.addressStreetNumber && ` ${request.invoice?.addressStreetNumber}`}
              { request.invoice?.addressBus && `/${request.invoice?.addressBus}`}
              {request.invoice?.addressZipCode && `, ${request.invoice?.addressZipCode}`}
              {` ${request.invoice?.addressCity}`}
            </LabelValue>
          </Grid>
        </PanelContent>
      </Paper>
    ) : null;
  };
