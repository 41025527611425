import { CircularProgress, Grid } from '@material-ui/core';
import React, { FunctionComponent, PropsWithChildren } from 'react';

type IProps = {
  loading?: boolean;
  style?: object;
  size?: 'icon' | 'small' | 'default';
};

export const Loader: FunctionComponent<PropsWithChildren<IProps>> = ({
  children,
  loading,
  size = 'default',
  style,
}) => {
  if (!loading && children) {
    return <>{children}</>;
  }

  if (size === 'icon') {
    return <CircularProgress data-testid="icon-loader" size={18} style={style} />;
  }

  if (size === 'small') {
    return <CircularProgress data-testid="small-loader" size={20} style={style} />;
  }

  return (
    <Grid container data-testid="loader" justify="center" style={style}>
      <Grid item>
        <CircularProgress data-testid="loading" />
      </Grid>
    </Grid>
  );
};
