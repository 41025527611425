import { WorkOrderItemTypes } from '../planning.constants';

export const getWorkOrderItemTypeIcon = (type: WorkOrderItemTypes) => {
  switch (type) {
    case WorkOrderItemTypes.pick_up:
      return 'arrow_upward';
    case WorkOrderItemTypes.drop_off:
      return 'arrow_downward';
    case WorkOrderItemTypes.update:
      return 'edit';
    case WorkOrderItemTypes.replace:
      return 'compare_arrows';
    case WorkOrderItemTypes.notification:
      return 'notifications';
    case WorkOrderItemTypes.pickup_overdue:
      return 'arrow_upward';
    case WorkOrderItemTypes.pick_up_construction_zone_signage:
      return 'fence';
    case WorkOrderItemTypes.retrieve:
    default:
      return 'location_searching';
  }
};
