import { Button } from '../../../common/components/buttons/asign-button-extensions';
import * as React from 'react';
import { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { translate } from '../../../common/translations/translate';
import requestExport from '../../../assets/images/request-export.svg';

interface IProps {
  onExport(): void;
}

const useStyles = createUseStyles({
  export: {
    height: '15px',
    marginTop: -1,
    marginRight: 8,
  },
});

export const ExportResultsButton: FC<IProps> = ({ onExport }) => {
  const C = useStyles();

  return (
    <Button.White
      dataTestId="exportButton"
      debounceTime={1000}
      onClick={onExport}
      startIcon={<img className={C.export} src={requestExport} alt={translate('Requests.Header.ExportResults')} />}
    >
      {translate('Requests.Header.ExportResults')}
    </Button.White>
  );
};
